/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        UsagePackageType,
        UsagePackageTypePage,
    } from '../models';
        import {
            UsagePackageTypeFromJSON,
            UsagePackageTypeToJSON,
            UsagePackageTypePageFromJSON,
            UsagePackageTypePageToJSON,
        } from '../models';

            export interface UsagePackageApiGetAvailableUsagePackageTypesRequest {
                pageNumber: number;
                pageSize: number;
                includeDeleted?: boolean | null;
                search?: string;
                country?: string;
                dataPackageEnabled?: boolean | null;
                smsPackageEnabled?: boolean | null;
                usagePackageTypeAssigned?: boolean | null;
                networkCredentialType?: string | null;
            }

            export interface UsagePackageApiGetUsagePackageTypeRequest {
                usagePackageTypeId: string;
            }

        /**
        * 
        */
            export class UsagePackageApi extends runtime.BaseAPI {

            /**
                * Returns all available usage packages
                * Get usage package types
            */
            async getAvailableUsagePackageTypesRaw(requestParameters: UsagePackageApiGetAvailableUsagePackageTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsagePackageTypePage>> {
                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getAvailableUsagePackageTypes.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getAvailableUsagePackageTypes.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.includeDeleted !== undefined) {
                            queryParameters['includeDeleted'] = requestParameters.includeDeleted;
                    }

                    if (requestParameters.search !== undefined) {
                            queryParameters['search'] = requestParameters.search;
                    }

                    if (requestParameters.country !== undefined) {
                            queryParameters['country'] = requestParameters.country;
                    }

                    if (requestParameters.dataPackageEnabled !== undefined) {
                            queryParameters['dataPackageEnabled'] = requestParameters.dataPackageEnabled;
                    }

                    if (requestParameters.smsPackageEnabled !== undefined) {
                            queryParameters['smsPackageEnabled'] = requestParameters.smsPackageEnabled;
                    }

                    if (requestParameters.usagePackageTypeAssigned !== undefined) {
                            queryParameters['usagePackageTypeAssigned'] = requestParameters.usagePackageTypeAssigned;
                    }

                    if (requestParameters.networkCredentialType !== undefined) {
                            queryParameters['networkCredentialType'] = requestParameters.networkCredentialType;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/usagepackagetypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => UsagePackageTypePageFromJSON(jsonValue));
            }

            /**
                * Returns all available usage packages
                * Get usage package types
            */
                async getAvailableUsagePackageTypes(requestParameters: UsagePackageApiGetAvailableUsagePackageTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsagePackageTypePage> {
                    const response = await this.getAvailableUsagePackageTypesRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get a single usage package type by its id.
                * Get usage package type
            */
            async getUsagePackageTypeRaw(requestParameters: UsagePackageApiGetUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UsagePackageType>> {
                    if (requestParameters.usagePackageTypeId === null || requestParameters.usagePackageTypeId === undefined) {
                    throw new runtime.RequiredError('usagePackageTypeId','Required parameter requestParameters.usagePackageTypeId was null or undefined when calling getUsagePackageType.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/usagepackagetypes/{usagePackageTypeId}`.replace(`{${"usagePackageTypeId"}}`, encodeURIComponent(String(requestParameters.usagePackageTypeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => UsagePackageTypeFromJSON(jsonValue));
            }

            /**
                * Get a single usage package type by its id.
                * Get usage package type
            */
                async getUsagePackageType(requestParameters: UsagePackageApiGetUsagePackageTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UsagePackageType> {
                    const response = await this.getUsagePackageTypeRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
