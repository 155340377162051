/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MetricGroup = {
    Customer: 'CUSTOMER',
    BillingGroup: 'BILLING_GROUP',
    SimCard: 'SIM_CARD',
    Unknown: 'UNKNOWN'
} as const;
export type MetricGroup = typeof MetricGroup[keyof typeof MetricGroup];


export function MetricGroupFromJSON(json: any): MetricGroup {
    return MetricGroupFromJSONTyped(json, false);
}

export function MetricGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetricGroup {
    return json as MetricGroup;
}

export function MetricGroupToJSON(value?: MetricGroup | null): any {
    return value as any;
}

