/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeviceACLUpdateRequestAclConfigsInnerAclEntriesInner
 */
export interface DeviceACLUpdateRequestAclConfigsInnerAclEntriesInner {
    /**
     * 
     * @type {string}
     * @memberof DeviceACLUpdateRequestAclConfigsInnerAclEntriesInner
     */
    lanHostId: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceACLUpdateRequestAclConfigsInnerAclEntriesInner
     */
    note?: string;
}

/**
 * Check if a given object implements the DeviceACLUpdateRequestAclConfigsInnerAclEntriesInner interface.
 */
export function instanceOfDeviceACLUpdateRequestAclConfigsInnerAclEntriesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lanHostId" in value;

    return isInstance;
}

export function DeviceACLUpdateRequestAclConfigsInnerAclEntriesInnerFromJSON(json: any): DeviceACLUpdateRequestAclConfigsInnerAclEntriesInner {
    return DeviceACLUpdateRequestAclConfigsInnerAclEntriesInnerFromJSONTyped(json, false);
}

export function DeviceACLUpdateRequestAclConfigsInnerAclEntriesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceACLUpdateRequestAclConfigsInnerAclEntriesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lanHostId': json['lanHostId'],
        'note': !exists(json, 'note') ? undefined : json['note'],
    };
}

export function DeviceACLUpdateRequestAclConfigsInnerAclEntriesInnerToJSON(value?: DeviceACLUpdateRequestAclConfigsInnerAclEntriesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lanHostId': value.lanHostId,
        'note': value.note,
    };
}

