import React from "react";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    CobiraTable,
    ControlledAutoCompleteSingleSelectInput,
    ControlledSwitch,
    FormContainer,
    FormField,
    Paper,
} from "@cobira/ui-library";
import { Button, Heading, HStack, Textarea, VStack } from "@chakra-ui/react";
import { DeviceACLMode } from "../../api";
import { LanHostAndACL } from "../../hooks/useLanHostACLAggregations";
import { createColumnHelper } from "@tanstack/react-table";
import DeviceLanHostState from "../../components/Device/DeviceLanHostState/DeviceLanHostState";

const UpdateDeviceACLFormSchema = z.object({
    note: z.string().optional(),
    aclMode: z.nativeEnum(DeviceACLMode),
    lanHosts: z.array(
        z.object({
            id: z.string(),
            portId: z.string(),
            mac: z.string(),
            clientId: z.string(),
            ip: z.string(),
            hostName: z.string().optional(),
            vendor: z.string().optional(),
            connected: z.boolean(),
            fetchDate: z.date(),
            configured: z.boolean(),
        }),
    ),
});

export type UpdateDeviceACLFormSchemaType = z.infer<typeof UpdateDeviceACLFormSchema>;

export interface UpdateDeviceACLFormProps {
    lanHosts: LanHostAndACL[];
    onSubmit: (form: UpdateDeviceACLFormSchemaType) => void;
    onCancel?: () => void;
}

const lanHostColumn = createColumnHelper<LanHostAndACL>();

const UpdateDeviceACLForm = ({ lanHosts, onSubmit }: UpdateDeviceACLFormProps) => {
    const {
        formState: { errors },
        handleSubmit,
        register,
        control,
        getValues,
    } = useForm<UpdateDeviceACLFormSchemaType>({
        resolver: zodResolver(UpdateDeviceACLFormSchema),
        defaultValues: {
            aclMode: DeviceACLMode.Whitelist,
            note: undefined,
            lanHosts: lanHosts.sort((a, b) => (a.portId > b.portId ? 1 : -1)),
        },
    });

    const lanHostFormValues = getValues();
    const COLUMNS = [
        lanHostColumn.accessor("mac", {
            id: "mac",
            header: "MAC",
            cell: row => row.getValue(),
        }),
        lanHostColumn.accessor("clientId", {
            id: "clientId",
            header: "Client ID",
            cell: row => row.getValue(),
        }),
        lanHostColumn.accessor("ip", {
            id: "ip",
            header: "IP",
            cell: row => row.getValue(),
        }),
        lanHostColumn.accessor("hostName", {
            id: "hostName",
            header: "Hostname",
            cell: row => row.getValue(),
        }),
        lanHostColumn.accessor("vendor", {
            id: "vendor",
            header: "Vendor",
            cell: row => row.getValue(),
        }),
        lanHostColumn.accessor(row => row, {
            id: "connected",
            header: "Status",
            cell: row => (
                <DeviceLanHostState fetchDate={row.getValue().fetchDate} connected={row.getValue().connected} />
            ),
        }),
        lanHostColumn.accessor(row => row, {
            id: "acl",
            header: "Allow in ACL",
            cell: row => <ControlledSwitch control={control} name={`lanHosts.${row.row.index}.configured`} />,
        }),
    ];

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <VStack w={"100%"} align={"stretch"} gap={4}>
                <Paper
                    header={
                        <HStack w={"100%"}>
                            <Heading size={"md"}>ACL Mode Details</Heading>
                            <Button ml={"auto"} type={"submit"}>
                                Confirm ACL changes
                            </Button>
                        </HStack>
                    }
                >
                    <FormContainer>
                        <HStack w={"100%"} align={"stretch"} gap={4}>
                            <FormField label={"ACL Mode"} error={errors?.aclMode}>
                                <ControlledAutoCompleteSingleSelectInput
                                    control={{ control: control, name: "aclMode" }}
                                    input={{
                                        items: [DeviceACLMode.Whitelist],
                                        autocompleteAbleMapping: value => value,
                                        displayMapping: value => value,
                                        isDisabled: true,
                                        isClearable: false,
                                    }}
                                />
                            </FormField>
                            <FormField label={"ACL Update Note"} error={errors?.note}>
                                <Textarea
                                    id={"note"}
                                    placeholder={"Specify a reason for the ACL update"}
                                    {...register("note")}
                                />
                            </FormField>
                        </HStack>
                    </FormContainer>
                </Paper>
                <Heading size={"md"}>LAN Hosts</Heading>
                <CobiraTable columns={COLUMNS} data={lanHostFormValues.lanHosts} />
            </VStack>
        </form>
    );
};

export default UpdateDeviceACLForm;
