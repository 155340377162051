import React from "react";
import { Grid, Heading, HStack } from "@chakra-ui/react";
import { CopyButton, ImeiInfo, Paper, PlaceholderText, TextColumn } from "@cobira/ui-library";
import { Device } from "../../../api";

export interface DeviceModemDetailsPaperProps {
    device?: Device;
}

const DeviceModemDetailsPaper = ({ device }: DeviceModemDetailsPaperProps) => {
    return (
        <Paper header={"Modem Details"} withDivider cardProps={{ h: "100%" }}>
            <Grid templateColumns="auto" gap="4">
                <TextColumn
                    heading={
                        <HStack>
                            <Heading size={"sm"}>IMEI</Heading>
                            <CopyButton value={device?.imei || ""} />
                        </HStack>
                    }
                    grid
                >
                    <ImeiInfo imei={device?.imei} showCheckDigit as={"text"} />
                </TextColumn>
                <TextColumn heading="Modem Firmware" grid>
                    <PlaceholderText text={device?.modemFirmware} />
                </TextColumn>
                <TextColumn heading="Modem Manufacturer" grid>
                    <PlaceholderText text={device?.modemManufacturer} />
                </TextColumn>
            </Grid>
        </Paper>
    );
};

export default DeviceModemDetailsPaper;
