/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RadioTechnology } from './RadioTechnology';
import {
    RadioTechnologyFromJSON,
    RadioTechnologyFromJSONTyped,
    RadioTechnologyToJSON,
} from './RadioTechnology';

/**
 * 
 * @export
 * @interface SimCardNetworkStateSubscriberData
 */
export interface SimCardNetworkStateSubscriberData {
    /**
     * 
     * @type {string}
     * @memberof SimCardNetworkStateSubscriberData
     */
    imsi?: string;
    /**
     * 
     * @type {string}
     * @memberof SimCardNetworkStateSubscriberData
     */
    msisdn?: string;
    /**
     * 
     * @type {RadioTechnology}
     * @memberof SimCardNetworkStateSubscriberData
     */
    radioTechnology?: RadioTechnology;
    /**
     * 
     * @type {Date}
     * @memberof SimCardNetworkStateSubscriberData
     */
    recordedAt?: Date;
}

/**
 * Check if a given object implements the SimCardNetworkStateSubscriberData interface.
 */
export function instanceOfSimCardNetworkStateSubscriberData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SimCardNetworkStateSubscriberDataFromJSON(json: any): SimCardNetworkStateSubscriberData {
    return SimCardNetworkStateSubscriberDataFromJSONTyped(json, false);
}

export function SimCardNetworkStateSubscriberDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardNetworkStateSubscriberData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imsi': !exists(json, 'imsi') ? undefined : json['imsi'],
        'msisdn': !exists(json, 'msisdn') ? undefined : json['msisdn'],
        'radioTechnology': !exists(json, 'radioTechnology') ? undefined : RadioTechnologyFromJSON(json['radioTechnology']),
        'recordedAt': !exists(json, 'recordedAt') ? undefined : (new Date(json['recordedAt'])),
    };
}

export function SimCardNetworkStateSubscriberDataToJSON(value?: SimCardNetworkStateSubscriberData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imsi': value.imsi,
        'msisdn': value.msisdn,
        'radioTechnology': RadioTechnologyToJSON(value.radioTechnology),
        'recordedAt': value.recordedAt === undefined ? undefined : (value.recordedAt.toISOString()),
    };
}

