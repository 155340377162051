import React, { useState } from "react";
import { SearchAsyncSelectInput, SearchSingleInputProps, SearchStateConfig } from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { UsagePackageType } from "../../api";
import { useApi } from "../../hooks/useApi";

export interface SearchSelectUsagePackageTypeInputProps<
    K extends keyof TSearchStateConfig,
    TSearchStateConfig extends SearchStateConfig,
> extends Omit<SearchSingleInputProps<UsagePackageType, K, TSearchStateConfig>, "label"> {}

export const SearchSelectUsagePackageTypeInput = <
    K extends keyof TSearchStateConfig,
    TSearchStateConfig extends SearchStateConfig,
>({
    registration,
    placeholder = "Select Bundle",
}: SearchSelectUsagePackageTypeInputProps<K, TSearchStateConfig>) => {
    const { usagePackageApi } = useApi();
    const [usagePackageTypeSearchInput, setUsagePackageTypeSearchInput] = useState<string | null>(null);

    const { data: simConfigurations } = useQuery(["usagepackagetypes", usagePackageTypeSearchInput], () =>
        usagePackageApi.getAvailableUsagePackageTypes({
            pageNumber: 0,
            pageSize: 20,
            search: usagePackageTypeSearchInput || undefined,
            includeDeleted: true,
        }),
    );

    return (
        <SearchAsyncSelectInput
            placeholder={placeholder}
            items={simConfigurations?.content || []}
            displayMapping={value => value.customName}
            autocompleteAbleMapping={value => value.customName}
            registration={registration}
            onInputChange={setUsagePackageTypeSearchInput}
        />
    );
};
