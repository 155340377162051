/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Data } from './Data';
import {
    DataFromJSON,
    DataFromJSONTyped,
    DataToJSON,
} from './Data';

/**
 * 
 * @export
 * @interface UsagePackage
 */
export interface UsagePackage {
    /**
     * 
     * @type {string}
     * @memberof UsagePackage
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UsagePackage
     */
    packageState: UsagePackagePackageStateEnum;
    /**
     * 
     * @type {Data}
     * @memberof UsagePackage
     */
    dataRemaining: Data;
    /**
     * 
     * @type {number}
     * @memberof UsagePackage
     */
    smsRemaining: number;
    /**
     * 
     * @type {Date}
     * @memberof UsagePackage
     */
    activatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UsagePackage
     */
    terminatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UsagePackage
     */
    expiresAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof UsagePackage
     */
    usagePackageTypeId: string;
}


/**
 * @export
 */
export const UsagePackagePackageStateEnum = {
    Enqueued: 'ENQUEUED',
    Idle: 'IDLE',
    Active: 'ACTIVE',
    Terminated: 'TERMINATED',
    Unknown: 'UNKNOWN'
} as const;
export type UsagePackagePackageStateEnum = typeof UsagePackagePackageStateEnum[keyof typeof UsagePackagePackageStateEnum];


/**
 * Check if a given object implements the UsagePackage interface.
 */
export function instanceOfUsagePackage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "packageState" in value;
    isInstance = isInstance && "dataRemaining" in value;
    isInstance = isInstance && "smsRemaining" in value;
    isInstance = isInstance && "usagePackageTypeId" in value;

    return isInstance;
}

export function UsagePackageFromJSON(json: any): UsagePackage {
    return UsagePackageFromJSONTyped(json, false);
}

export function UsagePackageFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsagePackage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'packageState': json['packageState'],
        'dataRemaining': DataFromJSON(json['dataRemaining']),
        'smsRemaining': json['smsRemaining'],
        'activatedAt': !exists(json, 'activatedAt') ? undefined : (new Date(json['activatedAt'])),
        'terminatedAt': !exists(json, 'terminatedAt') ? undefined : (new Date(json['terminatedAt'])),
        'expiresAt': !exists(json, 'expiresAt') ? undefined : (new Date(json['expiresAt'])),
        'usagePackageTypeId': json['usagePackageTypeId'],
    };
}

export function UsagePackageToJSON(value?: UsagePackage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'packageState': value.packageState,
        'dataRemaining': DataToJSON(value.dataRemaining),
        'smsRemaining': value.smsRemaining,
        'activatedAt': value.activatedAt === undefined ? undefined : (value.activatedAt.toISOString()),
        'terminatedAt': value.terminatedAt === undefined ? undefined : (value.terminatedAt.toISOString()),
        'expiresAt': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString()),
        'usagePackageTypeId': value.usagePackageTypeId,
    };
}

