import React, { ReactNode } from "react";
import { PlaceholderText, SignalStrengthLabel, SignalStrengthTypes, TextColumn } from "@cobira/ui-library";
import { SimCardNetworkState } from "../../../api";
import { Heading } from "@chakra-ui/react";

interface NetworkStateSignalStrengthProps {
    signalStrengthType: SignalStrengthTypes;
    networkState?: SimCardNetworkState;
    loadingComponent?: ReactNode;
    placeholderComponent?: ReactNode;
}

export const NetworkStateSignalStrength = ({
    signalStrengthType,
    networkState,
    placeholderComponent = <PlaceholderText text={undefined} />,
}: NetworkStateSignalStrengthProps) => {
    const signal =
        signalStrengthType === SignalStrengthTypes.RSSI
            ? networkState?.signalCondition?.signalStrength
            : networkState?.signalCondition?.signalQuality;
    const heading = signalStrengthType === SignalStrengthTypes.RSSI ? "Signal Strength" : "Signal Quality";
    return (
        <TextColumn heading={<Heading size={"sm"}>{heading}</Heading>} grid>
            {signal ? <SignalStrengthLabel signal={signal} type={signalStrengthType} /> : <>{placeholderComponent}</>}
        </TextColumn>
    );
};
