/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActionActor } from './ActionActor';
import {
    ActionActorFromJSON,
    ActionActorFromJSONTyped,
    ActionActorToJSON,
} from './ActionActor';
import type { DeviceACLEntry } from './DeviceACLEntry';
import {
    DeviceACLEntryFromJSON,
    DeviceACLEntryFromJSONTyped,
    DeviceACLEntryToJSON,
} from './DeviceACLEntry';
import type { DeviceACLMode } from './DeviceACLMode';
import {
    DeviceACLModeFromJSON,
    DeviceACLModeFromJSONTyped,
    DeviceACLModeToJSON,
} from './DeviceACLMode';

/**
 * ACL configuration & rules configured for a device
 * @export
 * @interface DeviceACL
 */
export interface DeviceACL {
    /**
     * 
     * @type {DeviceACLMode}
     * @memberof DeviceACL
     */
    aclMode: DeviceACLMode;
    /**
     * 
     * @type {ActionActor}
     * @memberof DeviceACL
     */
    updatedBy: ActionActor;
    /**
     * 
     * @type {Array<DeviceACLEntry>}
     * @memberof DeviceACL
     */
    aclEntries: Array<DeviceACLEntry>;
}

/**
 * Check if a given object implements the DeviceACL interface.
 */
export function instanceOfDeviceACL(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "aclMode" in value;
    isInstance = isInstance && "updatedBy" in value;
    isInstance = isInstance && "aclEntries" in value;

    return isInstance;
}

export function DeviceACLFromJSON(json: any): DeviceACL {
    return DeviceACLFromJSONTyped(json, false);
}

export function DeviceACLFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceACL {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aclMode': DeviceACLModeFromJSON(json['aclMode']),
        'updatedBy': ActionActorFromJSON(json['updatedBy']),
        'aclEntries': ((json['aclEntries'] as Array<any>).map(DeviceACLEntryFromJSON)),
    };
}

export function DeviceACLToJSON(value?: DeviceACL | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aclMode': DeviceACLModeToJSON(value.aclMode),
        'updatedBy': ActionActorToJSON(value.updatedBy),
        'aclEntries': ((value.aclEntries as Array<any>).map(DeviceACLEntryToJSON)),
    };
}

