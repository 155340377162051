/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUser
 */
export interface CreateUser {
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUser
     */
    email: string;
    /**
     * Assign roles by their ids
     * @type {Array<string>}
     * @memberof CreateUser
     */
    roles: Array<string>;
}

/**
 * Check if a given object implements the CreateUser interface.
 */
export function instanceOfCreateUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "roles" in value;

    return isInstance;
}

export function CreateUserFromJSON(json: any): CreateUser {
    return CreateUserFromJSONTyped(json, false);
}

export function CreateUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'email': json['email'],
        'roles': json['roles'],
    };
}

export function CreateUserToJSON(value?: CreateUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'email': value.email,
        'roles': value.roles,
    };
}

