import { DeviceActionRequestFilter } from "../api";
import { useQuery } from "@tanstack/react-query";
import { mapFromDeviceActionFilterToSearchFilter } from "../utils/mapperUtils";
import { useMemo } from "react";
import { useApi } from "./useApi";

export interface UseDeviceCountProps {
    filter: DeviceActionRequestFilter;
    isLoading?: boolean;
}

export const useDeviceCount = ({ filter, isLoading = false }: UseDeviceCountProps): number => {
    const { deviceApi } = useApi();

    const queryKey = useMemo(() => {
        const deviceIdSetQueryKey = Array.from(filter?.deviceIdSet?.values() || []);

        return ["devices", filter, deviceIdSetQueryKey];
    }, [filter]);

    const { data: devices } = useQuery(
        queryKey,
        () => deviceApi.getDevices(mapFromDeviceActionFilterToSearchFilter(0, 1, filter)),
        {
            enabled: !!filter && !filter?.deviceIdSet?.size && !isLoading,
        },
    );

    return useMemo(() => {
        if (filter.deviceIdSet) {
            return filter.deviceIdSet.size;
        }

        return devices?.pageProperties?.totalElements || 0;
    }, [filter, devices]);
};
