import React from "react";
import { HStack, IconButton, Text, useBoolean } from "@chakra-ui/react";
import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { FaIcon } from "@cobira/ui-library";
import DeviceACLStateModal from "../DeviceACLStateModal/DeviceACLStateModal";
import { LanHostAndACL } from "../../../hooks/useLanHostACLAggregations";

export interface DeviceACLStateProps {
    host: LanHostAndACL;
}

const DeviceACLState = ({ host }: DeviceACLStateProps) => {
    const [isOpen, { on, off }] = useBoolean(false);
    if (host.configured) {
        return (
            <>
                <HStack gap={1}>
                    <Text>Allowed</Text>
                    <IconButton
                        aria-label={"view-details"}
                        icon={<FaIcon icon={faEye} />}
                        isRound={true}
                        h={"fit-content"}
                        variant={"icon"}
                        onClick={on}
                    />
                </HStack>
                <DeviceACLStateModal isOpen={isOpen} onClose={off} host={host} />
            </>
        );
    } else {
        return <Text>Blocked</Text>;
    }
};

export default DeviceACLState;
