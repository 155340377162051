import React, { useMemo } from "react";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    HStack,
    TabPanel,
    Text,
} from "@chakra-ui/react";
import { Device } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { FaIcon, Loading, NotFound } from "@cobira/ui-library";
import { faEthernet } from "@fortawesome/pro-duotone-svg-icons";
import DeviceLanHostTable from "../../../../components/Device/DeviceLanHostTable/DeviceLanHostTable";
import { useLanHostACLAggregations } from "../../../../hooks/useLanHostACLAggregations";

export interface DeviceLanHostsPanelProps {
    deviceQuery: UseQueryResult<Device>;
}

const DeviceLanHostsPanel = ({ deviceQuery: { data: device } }: DeviceLanHostsPanelProps) => {
    const { portGroupedLanHosts, lanHosts, isLoading } = useLanHostACLAggregations({ deviceId: device?.id });

    const PortAccordionItems = useMemo(() => {
        return Array.from(portGroupedLanHosts.entries()).map(entry => (
            <AccordionItem key={entry[0]}>
                <AccordionButton>
                    <HStack gap={4} w={"100%"}>
                        <FaIcon icon={faEthernet} />
                        <Text>Port {entry[0]}</Text>
                        <AccordionIcon ml={"auto"} />
                    </HStack>
                </AccordionButton>
                <AccordionPanel>
                    <DeviceLanHostTable lanHosts={entry[1]} />
                </AccordionPanel>
            </AccordionItem>
        ));
    }, [portGroupedLanHosts]);

    if (isLoading) {
        return (
            <TabPanel w={"100%"}>
                <Loading />
            </TabPanel>
        );
    }

    if (!device) {
        return (
            <TabPanel>
                <NotFound resource={"Device"} />
            </TabPanel>
        );
    }

    return (
        <TabPanel>
            {(lanHosts?.length || 0) > 0 && (
                <Accordion defaultIndex={[0]} allowMultiple>
                    {PortAccordionItems}
                </Accordion>
            )}
            {!lanHosts?.length && <NotFound resource={"LAN Hosts"} />}
        </TabPanel>
    );
};

export default DeviceLanHostsPanel;
