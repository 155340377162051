import React, { useMemo } from "react";
import {
    CobiraTable,
    Country,
    CountryInfo,
    DataPackageAllowance,
    Duration,
    getCountries,
    Page,
    PageTitle,
    PlaceholderText,
    SearchBar,
    SearchSelectInput,
    SearchSingleInput,
    singleInput,
    SmsPackageAllowance,
    usePageNavigation,
    usePersistedSearch,
    useUrlPagination,
} from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { NetworkCredentialType, UsagePackageType } from "../../../api";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import DeletedAt from "../../../components/DeletedAt/DeletedAt";
import { Permission } from "../../../utils/Permissions";
import RestrictedPage from "../../../components/RestrictedPage/RestrictedPage";
import { UsagePackageActivationTypeLabels } from "../../../labels/UsagePackageActivationTypeLabels";
import { BooleanLabels } from "../../../labels/BooleanLabels";
import { mapBooleanFilter } from "../../../utils/mapperUtils";
import { VStack } from "@chakra-ui/react";
import { SearchSelectNetworkCredentialTypeInput } from "../../../components/SearchSelectNetworkCredentialTypeInput/SearchSelectNetworkCredentialTypeInput";

const usagePackageTypeColumn = createColumnHelper<UsagePackageType>();
const COLUMNS = [
    usagePackageTypeColumn.accessor("customName", {
        id: "name",
        header: "Name",
        cell: row => row.getValue(),
    }),
    usagePackageTypeColumn.accessor("description", {
        id: "description",
        header: "Description",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    usagePackageTypeColumn.accessor("activationType", {
        id: "automaticActivationType",
        header: "Activation method",
        cell: row => UsagePackageActivationTypeLabels[row.getValue()],
    }),
    usagePackageTypeColumn.accessor("dataUsageAllowance", {
        id: "dataUsageAllowance",
        header: "Data Allowance",
        cell: row => (
            <DataPackageAllowance
                allowance={row.getValue().dataAllowance || undefined}
                enabled={row.getValue().enabled}
            />
        ),
    }),
    usagePackageTypeColumn.accessor("smsUsageAllowance", {
        id: "smsUsageAllowance",
        header: "SMS Allowance",
        cell: row => (
            <SmsPackageAllowance
                allowance={row.getValue().smsAllowance || undefined}
                enabled={row.getValue().enabled}
            />
        ),
    }),
    usagePackageTypeColumn.accessor("packageDuration", {
        id: "packageDuration",
        header: "Duration",
        cell: row => <Duration value={row.getValue()} />,
    }),
    usagePackageTypeColumn.accessor("supportedCountries", {
        id: "supportedCountries",
        header: "Countries",
        cell: row => row.getValue().length,
    }),
    usagePackageTypeColumn.accessor("deletedAt", {
        id: "deletedAt",
        header: "Deleted At",
        cell: row => <DeletedAt deletedAt={row.getValue()} />,
    }),
];

const UsagePackageTypeListPage = () => {
    const { usagePackageApi } = useApi();
    const { navigate } = usePageNavigation<UsagePackageType>({ route: value => `/usage-package-types/${value.id}` });

    const countries = useMemo(() => getCountries(), []);

    const { setPageState, resetPageState, pageState } = useUrlPagination({ pageIndex: 0, pageSize: 20 });
    const search = usePersistedSearch({
        persistenceKey: "usage-package-type-search",
        config: {
            search: singleInput<string>("Search"),
            country: singleInput<Country>("Country", { display: value => `${value.name} (${value.iso2})` }),
            includeDeleted: singleInput<boolean>("Include Deleted", {
                display: value => BooleanLabels(value),
            }),
            dataPackageEnabled: singleInput<boolean>("Data Enabled", {
                display: value => BooleanLabels(value),
            }),
            smsPackageEnabled: singleInput<boolean>("SMS Enabled", {
                display: value => BooleanLabels(value),
            }),
            assigned: singleInput<boolean>("Assigned to SIM", {
                display: value => BooleanLabels(value),
            }),
            inCatalogue: singleInput<boolean>("Assigned to Catalogue", {
                display: value => BooleanLabels(value),
            }),
            networkCredentialType: singleInput<NetworkCredentialType>("Network Credential Type", {
                display: value => `${value.imsiProvider}`,
            }),
        },
        onChange: resetPageState,
    });

    const { data: usagePackageTypes } = useQuery(["usagepackagetypes", pageState, search.state], () => {
        return usagePackageApi.getAvailableUsagePackageTypes({
            pageNumber: pageState.pageIndex,
            pageSize: pageState.pageSize,
            search: search.state?.search?.value || undefined,
            country: search.state?.country?.value?.iso2 || undefined,
            includeDeleted: mapBooleanFilter(search.state?.includeDeleted?.value),
            dataPackageEnabled: mapBooleanFilter(search.state?.dataPackageEnabled?.value),
            smsPackageEnabled: mapBooleanFilter(search.state?.smsPackageEnabled?.value),
            usagePackageTypeAssigned: mapBooleanFilter(search.state?.assigned?.value),
            networkCredentialType: search.state?.networkCredentialType?.value?.id,
        });
    });

    return (
        <Page
            title={<PageTitle title="Usage Package Types" />}
            permissions={{ values: [Permission.usagePackage.read], deniedComponent: <RestrictedPage /> }}
        >
            <VStack w={"100%"} gap={2}>
                <SearchBar
                    defaultFilterInputId={"search"}
                    useSearch={search}
                    filterInputs={[
                        {
                            id: "search",
                            menuLabel: "Search",
                            inputComponent: (
                                <SearchSingleInput
                                    registration={search.registerInput({ id: "search" })}
                                    placeholder={"Quick find..."}
                                />
                            ),
                        },
                        {
                            id: "country",
                            menuLabel: "Country",
                            inputComponent: (
                                <SearchSelectInput
                                    registration={search.registerInput({ id: "country" })}
                                    items={countries}
                                    autocompleteAbleMapping={value => `${value.name} (${value.iso2})`}
                                    displayMapping={value => <CountryInfo country={value} />}
                                />
                            ),
                        },
                        {
                            id: "includeDeleted",
                            menuLabel: "Include Deleted",
                            inputComponent: (
                                <SearchSelectInput
                                    registration={search.registerInput({ id: "includeDeleted" })}
                                    items={[true, false]}
                                    autocompleteAbleMapping={value => BooleanLabels(value)}
                                    displayMapping={value => BooleanLabels(value)}
                                />
                            ),
                        },
                        {
                            id: "dataPackageEnabled",
                            menuLabel: "Data Enabled",
                            inputComponent: (
                                <SearchSelectInput
                                    registration={search.registerInput({ id: "dataPackageEnabled" })}
                                    items={[true, false]}
                                    autocompleteAbleMapping={value => BooleanLabels(value)}
                                    displayMapping={value => BooleanLabels(value)}
                                />
                            ),
                        },
                        {
                            id: "smsPackageEnabled",
                            menuLabel: "SMS Enabled",
                            inputComponent: (
                                <SearchSelectInput
                                    registration={search.registerInput({ id: "smsPackageEnabled" })}
                                    items={[true, false]}
                                    autocompleteAbleMapping={value => BooleanLabels(value)}
                                    displayMapping={value => BooleanLabels(value)}
                                />
                            ),
                        },
                        {
                            id: "inUse",
                            menuLabel: "Assigned to SIM",
                            inputComponent: (
                                <SearchSelectInput
                                    registration={search.registerInput({ id: "assigned" })}
                                    items={[true, false]}
                                    autocompleteAbleMapping={value => BooleanLabels(value)}
                                    displayMapping={value => BooleanLabels(value)}
                                />
                            ),
                        },
                        {
                            id: "networkCredentialType",
                            menuLabel: "Network Credential Type",
                            inputComponent: (
                                <SearchSelectNetworkCredentialTypeInput
                                    registration={search.registerInput({ id: "networkCredentialType" })}
                                />
                            ),
                        },
                    ]}
                />
                <CobiraTable
                    columns={COLUMNS}
                    data={usagePackageTypes?.content || []}
                    withRowClick={{ enableHoverStyle: true, onRowClicked: navigate }}
                    withPagination={{
                        pageIndex: pageState.pageIndex,
                        pageSize: pageState.pageSize,
                        onPaginationChange: setPageState,
                        totalRowCount: usagePackageTypes?.pageProperties?.totalElements || 0,
                        totalPageCount: usagePackageTypes?.pageProperties?.totalPages || 0,
                    }}
                />
            </VStack>
        </Page>
    );
};

export default UsagePackageTypeListPage;
