/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        Role,
    } from '../models';
        import {
            RoleFromJSON,
            RoleToJSON,
        } from '../models';

            export interface RoleApiGetRoleRequest {
                roleId: string;
            }

        /**
        * 
        */
            export class RoleApi extends runtime.BaseAPI {

            /**
                * Get a single role.
                * Get role
            */
            async getRoleRaw(requestParameters: RoleApiGetRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Role>> {
                    if (requestParameters.roleId === null || requestParameters.roleId === undefined) {
                    throw new runtime.RequiredError('roleId','Required parameter requestParameters.roleId was null or undefined when calling getRole.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/roles/{roleId}`.replace(`{${"roleId"}}`, encodeURIComponent(String(requestParameters.roleId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => RoleFromJSON(jsonValue));
            }

            /**
                * Get a single role.
                * Get role
            */
                async getRole(requestParameters: RoleApiGetRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Role> {
                    const response = await this.getRoleRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all roles available.
                * Get roles
            */
            async getRolesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Role>>> {
            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleFromJSON));
            }

            /**
                * Get all roles available.
                * Get roles
            */
                async getRoles(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Role>> {
                    const response = await this.getRolesRaw(initOverrides);
                    return await response.value();
                }

        }
