/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        ImeiLock,
        NetworkCredential,
        NetworkCredentialPage,
        NetworkCredentialSettings,
        PhoneNumber,
    } from '../models';
        import {
            ImeiLockFromJSON,
            ImeiLockToJSON,
            NetworkCredentialFromJSON,
            NetworkCredentialToJSON,
            NetworkCredentialPageFromJSON,
            NetworkCredentialPageToJSON,
            NetworkCredentialSettingsFromJSON,
            NetworkCredentialSettingsToJSON,
            PhoneNumberFromJSON,
            PhoneNumberToJSON,
        } from '../models';

            export interface NetworkCredentialApiGetNetworkCredentialRequest {
                imsi: string;
            }

            export interface NetworkCredentialApiGetNetworkCredentialImeiLocksRequest {
                imsi: string;
            }

            export interface NetworkCredentialApiGetNetworkCredentialPhoneNumbersRequest {
                imsi: string;
            }

            export interface NetworkCredentialApiGetNetworkCredentialSettingsRequest {
                imsi: string;
            }

            export interface NetworkCredentialApiGetNetworkCredentialsRequest {
                pageNumber: number;
                pageSize: number;
            }

            export interface NetworkCredentialApiGetSimCardNetworkCredentialsRequest {
                icc: string;
            }

        /**
        * 
        */
            export class NetworkCredentialApi extends runtime.BaseAPI {

            /**
                * Get a specific network credential.
                * Get network credential
            */
            async getNetworkCredentialRaw(requestParameters: NetworkCredentialApiGetNetworkCredentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NetworkCredential>> {
                    if (requestParameters.imsi === null || requestParameters.imsi === undefined) {
                    throw new runtime.RequiredError('imsi','Required parameter requestParameters.imsi was null or undefined when calling getNetworkCredential.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/networkcredentials/{imsi}`.replace(`{${"imsi"}}`, encodeURIComponent(String(requestParameters.imsi))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => NetworkCredentialFromJSON(jsonValue));
            }

            /**
                * Get a specific network credential.
                * Get network credential
            */
                async getNetworkCredential(requestParameters: NetworkCredentialApiGetNetworkCredentialRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NetworkCredential> {
                    const response = await this.getNetworkCredentialRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get configured IMEI locks for a network credential. These will be enforced based on the value of the IMEI lock violation behaviour in the network credential settings.
                * Get IMEI locks for a network credential
            */
            async getNetworkCredentialImeiLocksRaw(requestParameters: NetworkCredentialApiGetNetworkCredentialImeiLocksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ImeiLock>>> {
                    if (requestParameters.imsi === null || requestParameters.imsi === undefined) {
                    throw new runtime.RequiredError('imsi','Required parameter requestParameters.imsi was null or undefined when calling getNetworkCredentialImeiLocks.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/networkcredentials/{imsi}/imeilocks`.replace(`{${"imsi"}}`, encodeURIComponent(String(requestParameters.imsi))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ImeiLockFromJSON));
            }

            /**
                * Get configured IMEI locks for a network credential. These will be enforced based on the value of the IMEI lock violation behaviour in the network credential settings.
                * Get IMEI locks for a network credential
            */
                async getNetworkCredentialImeiLocks(requestParameters: NetworkCredentialApiGetNetworkCredentialImeiLocksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ImeiLock>> {
                    const response = await this.getNetworkCredentialImeiLocksRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Fetch all available phone numbers for the specified network credential.
                * Get network credential phone numbers
            */
            async getNetworkCredentialPhoneNumbersRaw(requestParameters: NetworkCredentialApiGetNetworkCredentialPhoneNumbersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PhoneNumber>>> {
                    if (requestParameters.imsi === null || requestParameters.imsi === undefined) {
                    throw new runtime.RequiredError('imsi','Required parameter requestParameters.imsi was null or undefined when calling getNetworkCredentialPhoneNumbers.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/networkcredentials/{imsi}/phonenumbers`.replace(`{${"imsi"}}`, encodeURIComponent(String(requestParameters.imsi))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PhoneNumberFromJSON));
            }

            /**
                * Fetch all available phone numbers for the specified network credential.
                * Get network credential phone numbers
            */
                async getNetworkCredentialPhoneNumbers(requestParameters: NetworkCredentialApiGetNetworkCredentialPhoneNumbersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PhoneNumber>> {
                    const response = await this.getNetworkCredentialPhoneNumbersRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get the settings for a single network credential.
                * Get network credential settings
            */
            async getNetworkCredentialSettingsRaw(requestParameters: NetworkCredentialApiGetNetworkCredentialSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NetworkCredentialSettings>> {
                    if (requestParameters.imsi === null || requestParameters.imsi === undefined) {
                    throw new runtime.RequiredError('imsi','Required parameter requestParameters.imsi was null or undefined when calling getNetworkCredentialSettings.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/networkcredentials/{imsi}/settings`.replace(`{${"imsi"}}`, encodeURIComponent(String(requestParameters.imsi))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => NetworkCredentialSettingsFromJSON(jsonValue));
            }

            /**
                * Get the settings for a single network credential.
                * Get network credential settings
            */
                async getNetworkCredentialSettings(requestParameters: NetworkCredentialApiGetNetworkCredentialSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NetworkCredentialSettings> {
                    const response = await this.getNetworkCredentialSettingsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all network credentials available to your account.  Network credentials are tied to sim cards.
                * Get network credentials
            */
            async getNetworkCredentialsRaw(requestParameters: NetworkCredentialApiGetNetworkCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NetworkCredentialPage>> {
                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getNetworkCredentials.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getNetworkCredentials.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/networkcredentials`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => NetworkCredentialPageFromJSON(jsonValue));
            }

            /**
                * Get all network credentials available to your account.  Network credentials are tied to sim cards.
                * Get network credentials
            */
                async getNetworkCredentials(requestParameters: NetworkCredentialApiGetNetworkCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NetworkCredentialPage> {
                    const response = await this.getNetworkCredentialsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all currently configured network credentials for the selected SIM card.
                * Get all network credentials configured for the SIM card.
            */
            async getSimCardNetworkCredentialsRaw(requestParameters: NetworkCredentialApiGetSimCardNetworkCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NetworkCredential>>> {
                    if (requestParameters.icc === null || requestParameters.icc === undefined) {
                    throw new runtime.RequiredError('icc','Required parameter requestParameters.icc was null or undefined when calling getSimCardNetworkCredentials.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/simcards/{icc}/networkcredentials`.replace(`{${"icc"}}`, encodeURIComponent(String(requestParameters.icc))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NetworkCredentialFromJSON));
            }

            /**
                * Get all currently configured network credentials for the selected SIM card.
                * Get all network credentials configured for the SIM card.
            */
                async getSimCardNetworkCredentials(requestParameters: NetworkCredentialApiGetSimCardNetworkCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NetworkCredential>> {
                    const response = await this.getSimCardNetworkCredentialsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
