/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RadioTechnology } from './RadioTechnology';
import {
    RadioTechnologyFromJSON,
    RadioTechnologyFromJSONTyped,
    RadioTechnologyToJSON,
} from './RadioTechnology';

/**
 * Information regarding the attached cell tower
 * @export
 * @interface SimCardNetworkStateCell
 */
export interface SimCardNetworkStateCell {
    /**
     * 
     * @type {number}
     * @memberof SimCardNetworkStateCell
     */
    cellId?: number;
    /**
     * 
     * @type {number}
     * @memberof SimCardNetworkStateCell
     */
    locationAreaCode?: number;
    /**
     * 
     * @type {string}
     * @memberof SimCardNetworkStateCell
     * @deprecated
     */
    plmn?: string;
    /**
     * 
     * @type {RadioTechnology}
     * @memberof SimCardNetworkStateCell
     */
    radioTechnology?: RadioTechnology;
    /**
     * 
     * @type {Date}
     * @memberof SimCardNetworkStateCell
     */
    recordedAt?: Date;
}

/**
 * Check if a given object implements the SimCardNetworkStateCell interface.
 */
export function instanceOfSimCardNetworkStateCell(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SimCardNetworkStateCellFromJSON(json: any): SimCardNetworkStateCell {
    return SimCardNetworkStateCellFromJSONTyped(json, false);
}

export function SimCardNetworkStateCellFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardNetworkStateCell {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cellId': !exists(json, 'cellId') ? undefined : json['cellId'],
        'locationAreaCode': !exists(json, 'locationAreaCode') ? undefined : json['locationAreaCode'],
        'plmn': !exists(json, 'plmn') ? undefined : json['plmn'],
        'radioTechnology': !exists(json, 'radioTechnology') ? undefined : RadioTechnologyFromJSON(json['radioTechnology']),
        'recordedAt': !exists(json, 'recordedAt') ? undefined : (new Date(json['recordedAt'])),
    };
}

export function SimCardNetworkStateCellToJSON(value?: SimCardNetworkStateCell | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cellId': value.cellId,
        'locationAreaCode': value.locationAreaCode,
        'plmn': value.plmn,
        'radioTechnology': RadioTechnologyToJSON(value.radioTechnology),
        'recordedAt': value.recordedAt === undefined ? undefined : (value.recordedAt.toISOString()),
    };
}

