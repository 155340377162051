import { DeviceActionRequestFilter } from "../../../api";
import React from "react";
import RebootDeviceActionModal from "../Device/RebootDeviceActionModal/RebootDeviceActionModal";
import DiscoverHostsDeviceActionModal from "../Device/DiscoverHostsDeviceActionModal/DiscoverHostsDeviceActionModal";

export type DeviceActionType = "reboot" | "discover-host";

export interface DeviceActionModalsProps {
    actionType: DeviceActionType | null;
    filter: DeviceActionRequestFilter;
    onClose: () => void;
}

const DeviceActionModals = ({ actionType, filter, onClose }: DeviceActionModalsProps) => {
    if (actionType === null) {
        return null;
    }

    switch (actionType) {
        case "reboot":
            return <RebootDeviceActionModal filter={filter} isOpen onClose={onClose} />;
        case "discover-host":
            return <DiscoverHostsDeviceActionModal filter={filter} isOpen onClose={onClose} />;
    }
};

export default DeviceActionModals;
