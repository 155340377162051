import React from "react";
import { Heading, HStack } from "@chakra-ui/react";
import { DateTime, LocationUpdateStatusIndicator, TextColumn } from "@cobira/ui-library";
import { SimCardNetworkState } from "../../../api";

export interface NetworkStatePacketSwitchingStateProps {
    networkState?: SimCardNetworkState;
}

const NetworkStatePacketSwitchingState = ({ networkState }: NetworkStatePacketSwitchingStateProps) => {
    return (
        <TextColumn
            heading={
                <HStack>
                    <Heading size={"sm"}>Packet Switching State</Heading>
                    <LocationUpdateStatusIndicator status={networkState?.packetSwitchingLocationUpdate.status} />
                </HStack>
            }
            grid
        >
            <DateTime value={networkState?.packetSwitchingLocationUpdate?.recordedAt} />
        </TextColumn>
    );
};

export default NetworkStatePacketSwitchingState;
