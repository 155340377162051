/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TimeBucket = {
    Monthly: 'MONTHLY',
    Unknown: 'UNKNOWN'
} as const;
export type TimeBucket = typeof TimeBucket[keyof typeof TimeBucket];


export function TimeBucketFromJSON(json: any): TimeBucket {
    return TimeBucketFromJSONTyped(json, false);
}

export function TimeBucketFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeBucket {
    return json as TimeBucket;
}

export function TimeBucketToJSON(value?: TimeBucket | null): any {
    return value as any;
}

