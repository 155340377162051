/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        NetworkPage,
    } from '../models';
        import {
            NetworkPageFromJSON,
            NetworkPageToJSON,
        } from '../models';

            export interface NetworkApiGetNetworksRequest {
                pageNumber: number;
                pageSize: number;
                plmn?: string;
                country?: string;
                networkName?: string;
            }

        /**
        * 
        */
            export class NetworkApi extends runtime.BaseAPI {

            /**
                * Search in available networks.
                * Get networks
            */
            async getNetworksRaw(requestParameters: NetworkApiGetNetworksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NetworkPage>> {
                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getNetworks.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getNetworks.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.plmn !== undefined) {
                            queryParameters['plmn'] = requestParameters.plmn;
                    }

                    if (requestParameters.country !== undefined) {
                            queryParameters['country'] = requestParameters.country;
                    }

                    if (requestParameters.networkName !== undefined) {
                            queryParameters['networkName'] = requestParameters.networkName;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/networks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => NetworkPageFromJSON(jsonValue));
            }

            /**
                * Search in available networks.
                * Get networks
            */
                async getNetworks(requestParameters: NetworkApiGetNetworksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NetworkPage> {
                    const response = await this.getNetworksRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
