import React from "react";
import { Heading, HStack } from "@chakra-ui/react";
import { DateTime, LocationUpdateStatusIndicator, TextColumn } from "@cobira/ui-library";
import { SimCardNetworkState } from "../../../api";

export interface NetworkStateCircuitSwitchingStateProps {
    networkState?: SimCardNetworkState;
}

const NetworkStateCircuitSwitchingState = ({ networkState }: NetworkStateCircuitSwitchingStateProps) => {
    return (
        <TextColumn
            heading={
                <HStack>
                    <Heading size={"sm"}>Circuit Switching State</Heading>
                    <LocationUpdateStatusIndicator status={networkState?.circuitSwitchingLocationUpdate?.status} />
                </HStack>
            }
            grid
        >
            <DateTime value={networkState?.circuitSwitchingLocationUpdate?.recordedAt} />
        </TextColumn>
    );
};

export default NetworkStateCircuitSwitchingState;
