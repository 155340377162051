import React, { useCallback } from "react";
import { useParams } from "react-router";
import { useLanHostACLAggregations } from "../../../hooks/useLanHostACLAggregations";
import {
    Action,
    ActionCompletedNotification,
    ActionFailedNotification,
    Loading,
    Page,
    PageBackButton,
    PageTitle,
    useActionNotification,
} from "@cobira/ui-library";
import UpdateDeviceACLForm, {
    UpdateDeviceACLFormSchemaType,
} from "../../../forms/UpdateDeviceACLForm/UpdateDeviceACLForm";
import { useMutation } from "@tanstack/react-query";
import { DeviceApiUpdateDeviceAclRequest } from "../../../api";
import { useApi } from "../../../hooks/useApi";
import { Permission } from "../../../utils/Permissions";
import RestrictedPage from "../../../components/RestrictedPage/RestrictedPage";
import ActionInProgressNotification from "../../../components/ActionNotification/ActionInProgressNotification/ActionInProgressNotification";

const UpdateDeviceACLPage = () => {
    const { deviceApi, actionApi } = useApi();
    const { deviceId } = useParams();
    const { trackAction } = useActionNotification({});

    const { lanHosts, isLoading } = useLanHostACLAggregations({ deviceId: deviceId });

    const updateDeviceACL = useMutation<Action[], Error, DeviceApiUpdateDeviceAclRequest>(
        variables => deviceApi.updateDeviceAcl(variables),
        {
            onSuccess: data => {
                data.forEach(action => {
                    trackAction({
                        action: action,
                        fetchAction: actionId => actionApi.getAction({ actionId: actionId }),
                        options: {
                            successMessage: successMessage,
                            errorMessage: errorMessage,
                            loadingMessage: loadingMessage(action),
                        },
                    });
                });
            },
        },
    );

    const successMessage = useCallback((data: Action) => {
        return (
            <ActionCompletedNotification
                action={data}
                successTitle={"ACL Update"}
                successMessage={`ACL configuration updated for device`}
                failureComponent={<ActionFailedNotification title={"Failed to update ACL"} />}
            />
        );
    }, []);
    const errorMessage = useCallback(() => <ActionFailedNotification title={"Failed to update ACL"} />, []);
    const loadingMessage = useCallback(
        (action: Action) => (
            <ActionInProgressNotification
                actionApi={actionApi}
                actionId={action.id}
                title={"Updating device ACL configuration"}
                message={() => `ACL configuration update in progress`}
            />
        ),
        [actionApi],
    );

    const handleSubmit = useCallback(
        (form: UpdateDeviceACLFormSchemaType) => {
            updateDeviceACL.mutate({
                deviceACLUpdateRequest: {
                    aclConfigs: [
                        {
                            deviceId: deviceId || "",
                            aclMode: form.aclMode,
                            aclEntries: form.lanHosts
                                .filter(lanHost => lanHost.configured)
                                .map(lanHost => ({
                                    lanHostId: lanHost.id,
                                    note: form.note,
                                })),
                        },
                    ],
                },
            });
        },
        [deviceId, updateDeviceACL],
    );

    return (
        <Page
            title={<PageTitle title={"Update Device ACL"} />}
            navigation={<PageBackButton text={"Back to device"} path={`/devices/${deviceId}`} />}
            permissions={{
                values: [Permission.device.write, Permission.device.read],
                type: "allOf",
                deniedComponent: <RestrictedPage />,
            }}
        >
            {isLoading && <Loading fullScreen />}
            {!isLoading && <UpdateDeviceACLForm lanHosts={lanHosts || []} onSubmit={handleSubmit} />}
        </Page>
    );
};

export default UpdateDeviceACLPage;
