/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        Action,
        ActionClassifier,
        ActionItemPage,
        ActionItemState,
        ActionPage,
        ActionState,
    } from '../models';
        import {
            ActionFromJSON,
            ActionToJSON,
            ActionClassifierFromJSON,
            ActionClassifierToJSON,
            ActionItemPageFromJSON,
            ActionItemPageToJSON,
            ActionItemStateFromJSON,
            ActionItemStateToJSON,
            ActionPageFromJSON,
            ActionPageToJSON,
            ActionStateFromJSON,
            ActionStateToJSON,
        } from '../models';

            export interface ActionApiGetActionRequest {
                actionId: string;
            }

            export interface ActionApiGetActionItemsRequest {
                actionId: string;
                pageNumber: number;
                pageSize: number;
                itemState?: ActionItemState;
            }

            export interface ActionApiGetActionsRequest {
                pageSize: number;
                pageNumber: number;
                actionState?: Array<ActionState>;
                actionClassifier?: Set<ActionClassifier>;
                correlationId?: string;
                itemSearch?: string;
                actionActor?: string;
            }

        /**
        * 
        */
            export class ActionApi extends runtime.BaseAPI {

            /**
                * Gets a specific action.
                * Get action
            */
            async getActionRaw(requestParameters: ActionApiGetActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Action>> {
                    if (requestParameters.actionId === null || requestParameters.actionId === undefined) {
                    throw new runtime.RequiredError('actionId','Required parameter requestParameters.actionId was null or undefined when calling getAction.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/actions/{actionId}`.replace(`{${"actionId"}}`, encodeURIComponent(String(requestParameters.actionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => ActionFromJSON(jsonValue));
            }

            /**
                * Gets a specific action.
                * Get action
            */
                async getAction(requestParameters: ActionApiGetActionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Action> {
                    const response = await this.getActionRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get individual items as part of an action. If the action has a `totalItems` of 100, then there will be 100 action item entries.
                * Get action items
            */
            async getActionItemsRaw(requestParameters: ActionApiGetActionItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActionItemPage>> {
                    if (requestParameters.actionId === null || requestParameters.actionId === undefined) {
                    throw new runtime.RequiredError('actionId','Required parameter requestParameters.actionId was null or undefined when calling getActionItems.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getActionItems.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getActionItems.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.itemState !== undefined) {
                            queryParameters['itemState'] = requestParameters.itemState;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/actions/{actionId}/items`.replace(`{${"actionId"}}`, encodeURIComponent(String(requestParameters.actionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => ActionItemPageFromJSON(jsonValue));
            }

            /**
                * Get individual items as part of an action. If the action has a `totalItems` of 100, then there will be 100 action item entries.
                * Get action items
            */
                async getActionItems(requestParameters: ActionApiGetActionItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActionItemPage> {
                    const response = await this.getActionItemsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get action progress information
                * Action progress API
            */
            async getActionsRaw(requestParameters: ActionApiGetActionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ActionPage>> {
                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getActions.');
                    }

                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getActions.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.actionState) {
                        queryParameters['actionState'] = requestParameters.actionState;
                    }

                    if (requestParameters.actionClassifier) {
                        queryParameters['actionClassifier'] = requestParameters.actionClassifier;
                    }

                    if (requestParameters.correlationId !== undefined) {
                            queryParameters['correlationId'] = requestParameters.correlationId;
                    }

                    if (requestParameters.itemSearch !== undefined) {
                            queryParameters['itemSearch'] = requestParameters.itemSearch;
                    }

                    if (requestParameters.actionActor !== undefined) {
                            queryParameters['actionActor'] = requestParameters.actionActor;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/actions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => ActionPageFromJSON(jsonValue));
            }

            /**
                * Get action progress information
                * Action progress API
            */
                async getActions(requestParameters: ActionApiGetActionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ActionPage> {
                    const response = await this.getActionsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
