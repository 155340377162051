import { ActionClassifier } from "../api";

export const ActionClassifierLabels = {
    [ActionClassifier.SendSms]: "Send SMS",
    [ActionClassifier.SetImeiLockState]: "Set IMEI Lock Behaviour",
    [ActionClassifier.SimCardActivate]: "Activate SIM",
    [ActionClassifier.SimCardReset]: "Reset SIM",
    [ActionClassifier.SimCardSuspend]: "Suspend SIM",
    [ActionClassifier.UpdateImeiLock]: "Update Allowed IMEI",
    [ActionClassifier.UsagePackageAssignment]: "Assign Usage Package",
    [ActionClassifier.UsagePackageRevoke]: "Revoke Usage Package",
    [ActionClassifier.DeviceReboot]: "Reboot Device",
    [ActionClassifier.DeviceDiscoverHost]: "Refresh Host Table",
    [ActionClassifier.DeviceUpdateAcl]: "Update Device ACL",
    [ActionClassifier.Unknown]: "N/A",
};
