import React from "react";
import { LoadingSkeleton, PlaceholderText, QueryCell } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import InlineNetworkCredentialType from "../../InlineNetworkCredentialType/InlineNetworkCredentialType";

export interface NetworkCredentialTypeCellProps {
    networkCredentialTypeId?: string;
    showPlmn?: boolean;
    showProvider?: boolean;
}

export const NetworkCredentialTypeCell = ({
    networkCredentialTypeId,
    showPlmn = true,
    showProvider = true,
}: NetworkCredentialTypeCellProps) => {
    const { simCardApi } = useApi();
    if (!networkCredentialTypeId) {
        return <PlaceholderText text={null} />;
    }

    return (
        <QueryCell
            loadingComponent={<LoadingSkeleton />}
            query={{
                queryFn: () =>
                    simCardApi.getNetworkCredentialType({ networkCredentialTypeId: networkCredentialTypeId }),
                queryKey: ["networkcredentialtypes", networkCredentialTypeId],
            }}
            render={result => (
                <InlineNetworkCredentialType
                    networkCredentialType={result.data}
                    showPlmn={showPlmn}
                    showProvider={showProvider}
                />
            )}
        />
    );
};
