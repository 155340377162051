/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NetworkState } from './NetworkState';
import {
    NetworkStateFromJSON,
    NetworkStateFromJSONTyped,
    NetworkStateToJSON,
} from './NetworkState';

/**
 * Network credential representation
 * @export
 * @interface NetworkCredential
 */
export interface NetworkCredential {
    /**
     * 
     * @type {string}
     * @memberof NetworkCredential
     */
    imsi?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkCredential
     */
    networkCredentialTypeId?: string;
    /**
     * 
     * @type {NetworkState}
     * @memberof NetworkCredential
     */
    networkState?: NetworkState;
}

/**
 * Check if a given object implements the NetworkCredential interface.
 */
export function instanceOfNetworkCredential(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NetworkCredentialFromJSON(json: any): NetworkCredential {
    return NetworkCredentialFromJSONTyped(json, false);
}

export function NetworkCredentialFromJSONTyped(json: any, ignoreDiscriminator: boolean): NetworkCredential {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imsi': !exists(json, 'imsi') ? undefined : json['imsi'],
        'networkCredentialTypeId': !exists(json, 'networkCredentialTypeId') ? undefined : json['networkCredentialTypeId'],
        'networkState': !exists(json, 'networkState') ? undefined : NetworkStateFromJSON(json['networkState']),
    };
}

export function NetworkCredentialToJSON(value?: NetworkCredential | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imsi': value.imsi,
        'networkCredentialTypeId': value.networkCredentialTypeId,
        'networkState': NetworkStateToJSON(value.networkState),
    };
}

