import React from "react";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { SimCard } from "../../../../api";
import { Grid, GridItem, TabPanel, VStack } from "@chakra-ui/react";
import { Loading, NotFound } from "@cobira/ui-library";
import { useApi } from "../../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import DeviceInfoCards from "../../../../components/Device/DeviceInfoCards/DeviceInfoCards";
import DeviceHardwareDetailsPaper from "../../../../components/Device/DeviceHardwareDetailsPaper/DeviceHardwareDetailsPaper";
import DeviceModemDetailsPaper from "../../../../components/Device/DeviceModemDetailsPaper/DeviceModemDetailsPaper";
import NetworkStateMapLocation from "../../../../components/NetworkState/NetworkStateMapLocation/NetworkStateMapLocation";
import NetworkStateSummaryPaper from "../../../../components/NetworkState/NetworkStateSummaryPaper/NetworkStateSummaryPaper";

export interface HardwareTabPanelProps {
    query: UseQueryResult<SimCard>;
}

export const HardwareTabPanel = ({ query: { data: simCard } }: HardwareTabPanelProps) => {
    const { simCardApi, deviceApi } = useApi();

    const { data: device, isFetching: isLoadingDevice } = useQuery(
        ["simcards", simCard?.icc, "device"],
        () =>
            deviceApi
                .getDevices({
                    pageNumber: 0,
                    pageSize: 1,
                    icc: simCard?.icc || "",
                })
                .then(response => response?.content?.at(0)),
        {
            enabled: !!simCard?.icc,
        },
    );

    const { data: networkState } = useQuery(
        ["simcards", simCard?.icc, "networkstate"],
        () => simCardApi.getSimCardNetworkState({ icc: simCard?.icc || "" }),
        { enabled: simCard?.icc !== undefined },
    );

    if (isLoadingDevice) {
        return (
            <TabPanel>
                <Loading />
            </TabPanel>
        );
    }
    if (!device) {
        return (
            <TabPanel>
                <NotFound resource={"Device"} />
            </TabPanel>
        );
    }

    return (
        <TabPanel>
            <VStack w={"100%"} align={"stretch"} spacing={6}>
                <DeviceInfoCards device={device} />
                <NetworkStateSummaryPaper networkState={networkState} />
                <Grid
                    templateAreas={`"left-top right"
                                    "left-bottom right"`}
                    templateRows={"1fr 1fr"}
                    templateColumns={"3fr 5fr"}
                    columnGap={4}
                    rowGap={4}
                >
                    <GridItem area={"left-top"}>
                        <DeviceHardwareDetailsPaper device={device} />
                    </GridItem>
                    <GridItem area={"left-bottom"}>
                        <DeviceModemDetailsPaper device={device} />
                    </GridItem>
                    <GridItem area={"right"}>
                        <NetworkStateMapLocation networkState={networkState} />
                    </GridItem>
                </Grid>
            </VStack>
        </TabPanel>
    );
};
