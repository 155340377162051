import React, { useMemo, useState } from "react";
import {
    CobiraTable,
    Country,
    CountryInfo,
    getCountries,
    getCountry,
    LocationUpdateState,
    Page,
    PageTitle,
    PlaceholderText,
    SearchBar,
    SearchRangeInput,
    SearchSelectInput,
    SearchSingleInput,
    useColumnHelper,
    usePageNavigation,
    useUrlPagination,
} from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { SimCard } from "../../../api";
import { useQuery } from "@tanstack/react-query";
import { BillingGroupCell } from "./BillingGroupCell/BillingGroupCell";
import { SimCardStateCell } from "./SimCardStateCell/SimCardStateCell";
import { SimNetworkStateCell } from "./SimNetworkStateCell/SimNetworkStateCell";
import { SearchSelectBillingGroupInput } from "../../../components/SimCardSearch/SearchSelectBillingGroupInput/SearchSelectBillingGroupInput";
import { SearchSelectSimCardNetworkStateInput } from "../../../components/SimCardSearch/SearchSelectSimCardNetworkState/SearchSelectSimCardNetworkStateInput";
import { VStack } from "@chakra-ui/react";
import NetworkInfo from "../../../components/NetworkInfo/NetworkInfo";
import { filterHasValues } from "../../../utils/filterUtils";
import SimCardQuickFindTooltip from "../../../components/SimCardQuickFindTooltip/SimCardQuickFindTooltip";
import { Permission, WriteActionPermission } from "../../../utils/Permissions";
import RestrictedPage from "../../../components/RestrictedPage/RestrictedPage";
import SimCardActionModals, { SimActionType } from "../../../components/Modal/SimCardActionModals/SimCardActionModals";
import useSimCardSearch from "../../../hooks/useSimCardSearch";
import { SimCardNetworkCredentialsCell } from "../../../components/Cells/SimCardNetworkCredentialsCell/SimCardNetworkCredentialsCell";
import SimCardNetworkCredentialTypesCell from "../../../components/Cells/SimCardNetworkCredentialTypesCell/SimCardNetworkCredentialTypesCell";
import { SearchSelectSimConfigurationInput } from "../../../components/SearchSelectSimConfigurationInput/SearchSelectSimConfigurationInput";
import { SearchSelectNetworkCredentialTypeInput } from "../../../components/SearchSelectNetworkCredentialTypeInput/SearchSelectNetworkCredentialTypeInput";
import { SearchSelectUsagePackageTypeInput } from "../../../components/SearchSelectUsagePackageTypeInput/SearchSelectUsagePackageTypeInput";

const SimCardListPage = () => {
    const { simCardApi } = useApi();
    const { navigate } = usePageNavigation<SimCard>({ route: value => `/simcards/${value.icc}` });
    const [chosenAction, setChosenAction] = useState<SimActionType | null>(null);
    const [selectedIccs, setSelectedIccs] = useState<string[]>([]);
    const { pageState, setPageState, resetPageState } = useUrlPagination({
        pageIndex: 0,
        pageSize: 20,
    });
    const search = useSimCardSearch({ onChange: resetPageState });
    const countries = useMemo(() => getCountries(), []);
    const { state } = search;

    const actionFilter = useMemo(
        () => ({
            search: state?.search?.value || undefined,
            msisdnSearch: state?.msisdnSearch?.value || undefined,
            billingGroupId: state?.billingGroup?.value?.id || undefined,
            iccLowerBound: state?.iccRange?.from || undefined,
            iccUpperBound: state?.iccRange?.to || undefined,
            networkState: state?.simCardState?.value || undefined,
            iccSet: selectedIccs.length ? new Set<string>(selectedIccs) : undefined,
            country: state?.country?.value?.iso2 || undefined,
            networkCredentialTypeId: state?.networkCredentialType?.value?.id || undefined,
            simConfigurationId: state?.simConfiguration?.value?.id || undefined,
            plmnSearch: state?.plmnSearch?.value || undefined,
            usagePackageTypeId: state?.usagePackageType?.value?.id || undefined,
        }),
        [state, selectedIccs],
    );

    const { data: simCards, isLoading } = useQuery(["simcards", pageState, state], () => {
        return simCardApi.getSimCards({
            pageNumber: pageState.pageIndex,
            pageSize: pageState.pageSize,
            search: state?.search?.value || undefined,
            msisdnSearch: state?.msisdnSearch?.value || undefined,
            billingGroupId: state?.billingGroup?.value?.id || undefined,
            iccLowerBound: state?.iccRange?.from || undefined,
            iccUpperBound: state?.iccRange?.to || undefined,
            networkState: state?.simCardState?.value || undefined,
            country: state?.country?.value?.iso2 || undefined,
            networkCredentialTypeId: state?.networkCredentialType?.value?.id || undefined,
            simConfigurationId: state?.simConfiguration?.value?.id || undefined,
            plmn: state?.plmnSearch?.value || undefined,
            usagePackageTypeSearch: state?.usagePackageType?.value?.id || undefined,
        });
    });

    const columns = useColumnHelper<SimCard, string>(columnBuilder => [
        columnBuilder.accessor("icc", {
            id: "simCardState",
            header: "SIM State",
            cell: row => <SimCardStateCell icc={row.getValue()} />,
        }),
        columnBuilder.accessor("icc", {
            id: "icc",
            header: "ICC",
            cell: row => <PlaceholderText text={row.getValue()} />,
        }),
        columnBuilder.accessor("customName", {
            id: "customName",
            header: "Name",
            cell: row => <PlaceholderText text={row.getValue()} />,
        }),
        {
            columnDef: columnBuilder.accessor("billingGroupId", {
                id: "billingGroup",
                header: "Billing Group",
                cell: row => <BillingGroupCell billingGroupId={row.getValue()} />,
            }),
            permissions: {
                values: [Permission.billingGroup.read],
            },
        },
        columnBuilder.accessor("icc", {
            id: "activeImsi",
            header: "Active IMSI",
            cell: row => (
                <SimNetworkStateCell
                    icc={row.getValue()}
                    property={networkState => <PlaceholderText text={networkState?.networkCredential.imsi} />}
                />
            ),
        }),
        columnBuilder.accessor("icc", {
            id: "networkCredentials",
            header: "IMSIs",
            cell: row => <SimCardNetworkCredentialsCell icc={row.getValue()} />,
        }),
        columnBuilder.accessor("icc", {
            id: "networkCredentialTypes",
            header: "Network Credential Types",
            cell: row => <SimCardNetworkCredentialTypesCell icc={row.getValue()} />,
        }),
        columnBuilder.accessor("icc", {
            id: "ipAddress",
            header: "IP",
            cell: row => (
                <SimNetworkStateCell
                    icc={row.getValue()}
                    property={networkState => <PlaceholderText text={networkState?.ip.ip} />}
                />
            ),
        }),
        columnBuilder.accessor("icc", {
            id: "country",
            header: "Country",
            cell: row => (
                <SimNetworkStateCell
                    icc={row.getValue()}
                    property={networkState => (
                        <CountryInfo country={getCountry(networkState?.network?.country) as Country} withFlag />
                    )}
                />
            ),
        }),
        columnBuilder.accessor("icc", {
            id: "network",
            header: "PLMN",
            cell: row => (
                <SimNetworkStateCell
                    icc={row.getValue()}
                    property={networkState => <NetworkInfo plmn={networkState?.network?.plmn} />}
                />
            ),
        }),
        columnBuilder.accessor("icc", {
            id: "rat",
            header: "Radio Technology",
            cell: row => (
                <SimNetworkStateCell
                    icc={row.getValue()}
                    property={networkState => <PlaceholderText text={networkState?.subscriberData?.radioTechnology} />}
                />
            ),
        }),
        columnBuilder.accessor("icc", {
            id: "circuitSwitching",
            header: "Circuit Switching",
            cell: row => (
                <SimNetworkStateCell
                    icc={row.getValue()}
                    property={networkState => (
                        <LocationUpdateState
                            type={"CIRCUIT_SWITCHING"}
                            status={networkState?.circuitSwitchingLocationUpdate.status}
                        />
                    )}
                />
            ),
        }),
        columnBuilder.accessor("icc", {
            id: "packetSwitching",
            header: "Packet Switching",
            cell: row => (
                <SimNetworkStateCell
                    icc={row.getValue()}
                    property={networkState => (
                        <LocationUpdateState
                            type={"PACKAGE_SWITCHING"}
                            status={networkState?.packetSwitchingLocationUpdate.status}
                        />
                    )}
                />
            ),
        }),
    ]);

    return (
        <Page
            title={<PageTitle title="SIM Inventory" />}
            permissions={{ values: [Permission.simCard.read], deniedComponent: <RestrictedPage /> }}
        >
            <VStack w={"100%"} gap={2}>
                <SearchBar
                    defaultFilterInputId={"search"}
                    useSearch={search}
                    actions={{
                        enabled: selectedIccs.length > 0 || filterHasValues(actionFilter),
                        entries: [
                            {
                                id: "activate",
                                label: "Activate",
                                onClick: () => setChosenAction("activate"),
                                permissions: WriteActionPermission,
                            },
                            {
                                id: "Suspend",
                                label: "Suspend",
                                onClick: () => setChosenAction("suspend"),
                                permissions: WriteActionPermission,
                            },
                            {
                                id: "reset",
                                label: "Reset",
                                onClick: () => setChosenAction("reset"),
                                permissions: WriteActionPermission,
                            },
                            {
                                id: "set-imei-lock-state",
                                label: "Set IMEI Lock Behaviour",
                                onClick: () => setChosenAction("imei-lock-state"),
                                permissions: WriteActionPermission,
                            },
                            {
                                id: "send-sms",
                                label: "Send SMS",
                                onClick: () => setChosenAction("send-sms"),
                                permissions: WriteActionPermission,
                            },
                            {
                                id: "assign-usage-package",
                                label: "Assign Package",
                                onClick: () => setChosenAction("assign-usage-package"),
                                permissions: WriteActionPermission,
                            },
                            {
                                id: "revoke-usage-package",
                                label: "Revoke Package",
                                onClick: () => setChosenAction("revoke-usage-package"),
                                permissions: WriteActionPermission,
                            },
                        ],
                    }}
                    filterInputs={[
                        {
                            id: "search",
                            menuLabel: "Search",
                            inputComponent: (
                                <SearchSingleInput
                                    registration={search.registerInput({ id: "search" })}
                                    placeholder={"Quick find..."}
                                    tooltip={<SimCardQuickFindTooltip />}
                                />
                            ),
                        },
                        {
                            id: "iccRange",
                            menuLabel: "ICC Range",
                            inputComponent: (
                                <SearchRangeInput
                                    type={"number"}
                                    registration={search.registerRangeInput({ id: "iccRange" })}
                                    fromPlaceholder={"From ICC"}
                                    toPlaceholder={"To ICC"}
                                />
                            ),
                        },
                        {
                            id: "msisdnSearch",
                            menuLabel: "MSISDN Search",
                            inputComponent: (
                                <SearchSingleInput
                                    registration={search.registerInput({ id: "msisdnSearch" })}
                                    placeholder={"Search by MSISDN"}
                                />
                            ),
                        },
                        {
                            id: "billingGroupId",
                            menuLabel: "Billing Group",
                            inputComponent: (
                                <SearchSelectBillingGroupInput
                                    registration={search.registerInput({ id: "billingGroup" })}
                                />
                            ),
                            permissions: {
                                values: [Permission.billingGroup.read],
                            },
                        },
                        {
                            id: "simCardState",
                            menuLabel: "SIM Card State",
                            inputComponent: (
                                <SearchSelectSimCardNetworkStateInput
                                    registration={search.registerInput({ id: "simCardState" })}
                                />
                            ),
                        },
                        {
                            id: "networkCredentialType",
                            menuLabel: "Network Credential Type",
                            inputComponent: (
                                <SearchSelectNetworkCredentialTypeInput
                                    registration={search.registerInput({ id: "networkCredentialType" })}
                                />
                            ),
                        },
                        {
                            id: "country",
                            menuLabel: "Country",
                            inputComponent: (
                                <SearchSelectInput
                                    registration={search.registerInput({ id: "country" })}
                                    items={countries}
                                    autocompleteAbleMapping={value => `${value.name} (${value.iso2})`}
                                    displayMapping={value => <CountryInfo country={value} />}
                                />
                            ),
                        },
                        {
                            id: "simConfiguration",
                            menuLabel: "SIM Configuration",
                            inputComponent: (
                                <SearchSelectSimConfigurationInput
                                    registration={search.registerInput({ id: "simConfiguration" })}
                                />
                            ),
                        },
                        {
                            id: "plmnSearch",
                            menuLabel: "PLMN Search",
                            inputComponent: (
                                <SearchSingleInput
                                    registration={search.registerInput({ id: "plmnSearch" })}
                                    placeholder={"Search for PLMN"}
                                />
                            ),
                        },
                        {
                            id: "usagePackageType",
                            menuLabel: "Bundle",
                            permissions: {
                                values: [Permission.usagePackage.read],
                                type: "allOf",
                            },
                            inputComponent: (
                                <SearchSelectUsagePackageTypeInput
                                    registration={search.registerInput({ id: "usagePackageType" })}
                                />
                            ),
                        },
                    ]}
                />
                <CobiraTable
                    sizing={"fit-page"}
                    columns={columns}
                    data={simCards?.content || []}
                    isLoading={isLoading}
                    withPagination={{
                        pageIndex: pageState.pageIndex,
                        pageSize: pageState.pageSize,
                        onPaginationChange: setPageState,
                        totalRowCount: simCards?.pageProperties?.totalElements || 0,
                        totalPageCount: simCards?.pageProperties?.totalPages || 0,
                    }}
                    withRowClick={{
                        enableHoverStyle: true,
                        onRowClicked: navigate,
                    }}
                    withRowSelection={{
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        rowId: row => row.icc!,
                        onChange: setSelectedIccs,
                    }}
                    withColumnSelection={{
                        tableKey: "simcard-table",
                        maxColumnCount: 8,
                    }}
                />
            </VStack>
            <SimCardActionModals
                actionType={chosenAction}
                filter={actionFilter}
                onClose={() => setChosenAction(null)}
            />
        </Page>
    );
};

export default SimCardListPage;
