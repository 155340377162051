import { useApi } from "../../../hooks/useApi";
import {
    Action,
    ActionCompletedNotification,
    ActionFailedNotification,
    BasicModal,
    pluralize,
    useActionNotification,
    useNotifications,
} from "@cobira/ui-library";
import {
    ManageUsagePackageUsagePackageManagementActionEnum,
    SimCardActionRequestFilter,
    SimCardApiManageSimCardUsagePackageRequest,
} from "../../../api";
import { useMutation } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { Text, VStack } from "@chakra-ui/react";
import SelectUsagePackageForm, {
    SelectUsagePackageFormType,
} from "../../../forms/SelectUsagePackageForm/SelectUsagePackageForm";
import { useSimCardCount } from "../../../hooks/useSimCardCount";
import ActionInProgressNotification from "../../ActionNotification/ActionInProgressNotification/ActionInProgressNotification";

export interface AssignUsagePackageModalProps {
    filter: SimCardActionRequestFilter;
    isOpen: boolean;
    onClose: () => void;
}

const AssignUsagePackageModal = ({ isOpen, filter, onClose }: AssignUsagePackageModalProps) => {
    const { simCardApi, actionApi } = useApi();
    const { trackAction } = useActionNotification({});
    const notifications = useNotifications();

    const simCardCount = useSimCardCount({ filter });

    const assignUsagePackage = useMutation<Action[], Error, SimCardApiManageSimCardUsagePackageRequest>(
        variables => simCardApi.manageSimCardUsagePackage(variables),
        {
            onSuccess: data => {
                data.forEach(action => {
                    trackAction({
                        action: action,
                        fetchAction: actionId => actionApi.getAction({ actionId: actionId }),
                        options: {
                            successMessage: successMessage,
                            errorMessage: errorMessage,
                            loadingMessage: loadingMessage(action),
                        },
                    });
                });
            },
            onError: error => notifications.error(error?.message),
        },
    );

    const handleSubmit = useCallback(
        (form: SelectUsagePackageFormType) => {
            assignUsagePackage.mutate({
                manageUsagePackage: {
                    simCardFilter: filter,
                    usagePackageManagementAction: ManageUsagePackageUsagePackageManagementActionEnum.Assign,
                    usagePackageTypeId: form.usagePackageType.id,
                },
            });
            onClose();
        },
        [assignUsagePackage, filter, onClose],
    );

    const successMessage = useCallback((data: Action) => {
        return (
            <ActionCompletedNotification
                action={data}
                successTitle={"Usage Package Assigned"}
                successMessage={`New usage package assigned to ${data.totalItems} SIM ${pluralize(
                    "Cards",
                    data.totalItems,
                )}`}
                failureComponent={<ActionFailedNotification title={"Failed to assign usage package"} />}
            />
        );
    }, []);
    const errorMessage = useCallback(() => <ActionFailedNotification title={"Failed to assign usage package"} />, []);
    const loadingMessage = useCallback(
        (action: Action) => (
            <ActionInProgressNotification
                actionApi={actionApi}
                actionId={action.id}
                title={"Assigning usage package"}
                message={(processedSimCount, totalSimCount) =>
                    `Assigned usage package to ${processedSimCount} SIM ${pluralize(
                        "Card",
                        processedSimCount,
                    )} of ${totalSimCount}`
                }
            />
        ),
        [actionApi],
    );

    return (
        <BasicModal title={`Assign Usage Package`} onClose={onClose} isOpen={isOpen}>
            <VStack w={"100%"} align={"stretch"}>
                <Text>
                    Assign Usage Package to <b>{simCardCount}</b> SIM {pluralize("Card", simCardCount)}
                </Text>
                <SelectUsagePackageForm onSubmit={handleSubmit} onCancel={onClose} includeDeleted={false} />
            </VStack>
        </BasicModal>
    );
};

export default AssignUsagePackageModal;
