/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Details for a single PDP session of a SIM Card
 * @export
 * @interface PdpSession
 */
export interface PdpSession {
    /**
     * 
     * @type {string}
     * @memberof PdpSession
     */
    ip?: string;
    /**
     * 
     * @type {string}
     * @memberof PdpSession
     */
    apn?: string;
    /**
     * 
     * @type {string}
     * @memberof PdpSession
     */
    nsapi?: string;
    /**
     * 
     * @type {string}
     * @memberof PdpSession
     */
    packetGateway?: string;
    /**
     * 
     * @type {string}
     * @memberof PdpSession
     */
    pdpContextId?: string;
    /**
     * 
     * @type {Date}
     * @memberof PdpSession
     */
    openedAt?: Date;
}

/**
 * Check if a given object implements the PdpSession interface.
 */
export function instanceOfPdpSession(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PdpSessionFromJSON(json: any): PdpSession {
    return PdpSessionFromJSONTyped(json, false);
}

export function PdpSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PdpSession {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ip': !exists(json, 'ip') ? undefined : json['ip'],
        'apn': !exists(json, 'apn') ? undefined : json['apn'],
        'nsapi': !exists(json, 'nsapi') ? undefined : json['nsapi'],
        'packetGateway': !exists(json, 'packetGateway') ? undefined : json['packetGateway'],
        'pdpContextId': !exists(json, 'pdpContextId') ? undefined : json['pdpContextId'],
        'openedAt': !exists(json, 'openedAt') ? undefined : (new Date(json['openedAt'])),
    };
}

export function PdpSessionToJSON(value?: PdpSession | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ip': value.ip,
        'apn': value.apn,
        'nsapi': value.nsapi,
        'packetGateway': value.packetGateway,
        'pdpContextId': value.pdpContextId,
        'openedAt': value.openedAt === undefined ? undefined : (value.openedAt.toISOString()),
    };
}

