import React from "react";
import { TabPanel } from "@chakra-ui/react";
import { SimCard } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import DataUsageTable from "../../../../components/DataUsageTable/DataUsageTable";

interface DeviceDataSessionsPanelProps {
    simCardQuery: UseQueryResult<SimCard>;
}

const DeviceDataSessionsPanel = ({ simCardQuery: { data: simCard } }: DeviceDataSessionsPanelProps) => {
    return (
        <TabPanel w={"100%"}>
            <DataUsageTable tableKey={"device-data-usage-table"} simCard={simCard} />
        </TabPanel>
    );
};

export default DeviceDataSessionsPanel;
