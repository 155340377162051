import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { CobiraTable } from "@cobira/ui-library";
import DeviceLanHostState from "../DeviceLanHostState/DeviceLanHostState";
import DeviceACLState from "../DeviceACLState/DeviceACLState";
import { LanHostAndACL } from "../../../hooks/useLanHostACLAggregations";

const lanHostColumn = createColumnHelper<LanHostAndACL>();
const COLUMNS = [
    lanHostColumn.accessor("mac", {
        id: "mac",
        header: "MAC",
        cell: row => row.getValue(),
    }),
    lanHostColumn.accessor("clientId", {
        id: "clientId",
        header: "Client ID",
        cell: row => row.getValue(),
    }),
    lanHostColumn.accessor("ip", {
        id: "ip",
        header: "IP",
        cell: row => row.getValue(),
    }),
    lanHostColumn.accessor("hostName", {
        id: "hostName",
        header: "Hostname",
        cell: row => row.getValue(),
    }),
    lanHostColumn.accessor("vendor", {
        id: "vendor",
        header: "Vendor",
        cell: row => row.getValue(),
    }),
    lanHostColumn.accessor(row => row, {
        id: "connected",
        header: "Status",
        cell: row => <DeviceLanHostState fetchDate={row.getValue().fetchDate} connected={row.getValue().connected} />,
    }),
    lanHostColumn.accessor(row => row, {
        id: "acl",
        header: "ACL",
        cell: row => <DeviceACLState host={row.getValue()} />,
    }),
];

export interface DeviceLanHostTableProps {
    lanHosts: LanHostAndACL[];
}

const DeviceLanHostTable = ({ lanHosts }: DeviceLanHostTableProps) => {
    return <CobiraTable columns={COLUMNS} data={lanHosts} />;
};

export default DeviceLanHostTable;
