import React from "react";
import {
    chakraIcon,
    CobiraTable,
    Duration,
    formatDateTime,
    InfoPopover,
    Paper,
    PlaceholderText,
} from "@cobira/ui-library";
import { useQuery } from "@tanstack/react-query";
import { PdpSession, SimCard } from "../../../../api";
import { useApi } from "../../../../hooks/useApi";
import { createColumnHelper } from "@tanstack/react-table";
import { faGlobeWifi } from "@fortawesome/pro-regular-svg-icons";
import { Box } from "@chakra-ui/react";

export interface SimCardPdpSessionsPaperProps {
    simCard?: SimCard;
}

const pdpColumns = createColumnHelper<PdpSession>();
const COLUMNS = [
    pdpColumns.accessor("ip", {
        id: "ip",
        header: "IP",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    pdpColumns.accessor("nsapi", {
        id: "nsapi",
        header: "NSAPI",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    pdpColumns.accessor("apn", {
        id: "apn",
        header: "APN",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    pdpColumns.accessor("packetGateway", {
        id: "packetGateway",
        header: "Packet Gateway",
        cell: row => <PlaceholderText text={row.getValue()} />,
    }),
    pdpColumns.accessor("openedAt", {
        id: "openedAt",
        header: "Session Duration",
        cell: row => (
            <InfoPopover value={formatDateTime(row.getValue()) as string}>
                <Box>
                    <Duration units={["d", "h", "m"]} value={row.getValue()} />
                </Box>
            </InfoPopover>
        ),
    }),
];

const SimCardPdpSessionsPaper = ({ simCard }: SimCardPdpSessionsPaperProps) => {
    const { simCardApi } = useApi();
    const { data: pdpSessions, isLoading: isLoadingPdpSessions } = useQuery(
        ["simcards", simCard?.icc, "pdp"],
        () => simCardApi.getSimCardPdpSessions({ icc: simCard?.icc || "" }),
        { enabled: simCard?.icc !== undefined },
    );
    return (
        <Paper header={"Open PDP Sessions"} withDivider>
            <CobiraTable
                columns={COLUMNS}
                data={pdpSessions || []}
                isLoading={isLoadingPdpSessions}
                sizing={"default"}
                containerStyling={{}}
                noData={{
                    icon: chakraIcon(faGlobeWifi),
                    resource: "PDP Sessions",
                }}
            />
        </Paper>
    );
};

export default SimCardPdpSessionsPaper;
