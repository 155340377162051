/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        AccessToken,
        CreateAccessToken,
        UpdateAccessToken,
    } from '../models';
        import {
            AccessTokenFromJSON,
            AccessTokenToJSON,
            CreateAccessTokenFromJSON,
            CreateAccessTokenToJSON,
            UpdateAccessTokenFromJSON,
            UpdateAccessTokenToJSON,
        } from '../models';

            export interface AccessTokenApiCreateAccessTokenRequest {
                integrationId: string;
                createAccessToken: CreateAccessToken;
            }

            export interface AccessTokenApiGetAccessTokenRequest {
                integrationId: string;
                accessTokenId: string;
            }

            export interface AccessTokenApiGetAccessTokensRequest {
                integrationId: string;
            }

            export interface AccessTokenApiRevokeAccessTokenRequest {
                integrationId: string;
                accessTokenId: string;
            }

            export interface AccessTokenApiUpdateAccessTokenRequest {
                integrationId: string;
                accessTokenId: string;
                updateAccessToken: UpdateAccessToken;
            }

        /**
        * 
        */
            export class AccessTokenApi extends runtime.BaseAPI {

            /**
                * Create a new access token and display the secret. Once the returned object is no longer available the secret cannot be retrieved again.
                * Create access token
            */
            async createAccessTokenRaw(requestParameters: AccessTokenApiCreateAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccessToken>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling createAccessToken.');
                    }

                    if (requestParameters.createAccessToken === null || requestParameters.createAccessToken === undefined) {
                    throw new runtime.RequiredError('createAccessToken','Required parameter requestParameters.createAccessToken was null or undefined when calling createAccessToken.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}/accesstokens`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: CreateAccessTokenToJSON(requestParameters.createAccessToken),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => AccessTokenFromJSON(jsonValue));
            }

            /**
                * Create a new access token and display the secret. Once the returned object is no longer available the secret cannot be retrieved again.
                * Create access token
            */
                async createAccessToken(requestParameters: AccessTokenApiCreateAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccessToken> {
                    const response = await this.createAccessTokenRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get a specific access token by id.
                * Get access token
            */
            async getAccessTokenRaw(requestParameters: AccessTokenApiGetAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccessToken>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling getAccessToken.');
                    }

                    if (requestParameters.accessTokenId === null || requestParameters.accessTokenId === undefined) {
                    throw new runtime.RequiredError('accessTokenId','Required parameter requestParameters.accessTokenId was null or undefined when calling getAccessToken.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}/accesstokens/{accessTokenId}`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))).replace(`{${"accessTokenId"}}`, encodeURIComponent(String(requestParameters.accessTokenId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => AccessTokenFromJSON(jsonValue));
            }

            /**
                * Get a specific access token by id.
                * Get access token
            */
                async getAccessToken(requestParameters: AccessTokenApiGetAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccessToken> {
                    const response = await this.getAccessTokenRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Gets all active access tokens for the integration
                * Get access tokens
            */
            async getAccessTokensRaw(requestParameters: AccessTokenApiGetAccessTokensRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AccessToken>>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling getAccessTokens.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}/accesstokens`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AccessTokenFromJSON));
            }

            /**
                * Gets all active access tokens for the integration
                * Get access tokens
            */
                async getAccessTokens(requestParameters: AccessTokenApiGetAccessTokensRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AccessToken>> {
                    const response = await this.getAccessTokensRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Revoke an access token, the token will no longer allow access to the API once this is done.  Revoked tokens cannot be re-activated.
                * Revoke access token
            */
            async revokeAccessTokenRaw(requestParameters: AccessTokenApiRevokeAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling revokeAccessToken.');
                    }

                    if (requestParameters.accessTokenId === null || requestParameters.accessTokenId === undefined) {
                    throw new runtime.RequiredError('accessTokenId','Required parameter requestParameters.accessTokenId was null or undefined when calling revokeAccessToken.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}/accesstokens/{accessTokenId}`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))).replace(`{${"accessTokenId"}}`, encodeURIComponent(String(requestParameters.accessTokenId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Revoke an access token, the token will no longer allow access to the API once this is done.  Revoked tokens cannot be re-activated.
                * Revoke access token
            */
                async revokeAccessToken(requestParameters: AccessTokenApiRevokeAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.revokeAccessTokenRaw(requestParameters, initOverrides);
                }

            /**
                * Update details for an access token. It is not possible to update the secret.
                * Update access token
            */
            async updateAccessTokenRaw(requestParameters: AccessTokenApiUpdateAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccessToken>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling updateAccessToken.');
                    }

                    if (requestParameters.accessTokenId === null || requestParameters.accessTokenId === undefined) {
                    throw new runtime.RequiredError('accessTokenId','Required parameter requestParameters.accessTokenId was null or undefined when calling updateAccessToken.');
                    }

                    if (requestParameters.updateAccessToken === null || requestParameters.updateAccessToken === undefined) {
                    throw new runtime.RequiredError('updateAccessToken','Required parameter requestParameters.updateAccessToken was null or undefined when calling updateAccessToken.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}/accesstokens/{accessTokenId}`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))).replace(`{${"accessTokenId"}}`, encodeURIComponent(String(requestParameters.accessTokenId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
                                body: UpdateAccessTokenToJSON(requestParameters.updateAccessToken),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => AccessTokenFromJSON(jsonValue));
            }

            /**
                * Update details for an access token. It is not possible to update the secret.
                * Update access token
            */
                async updateAccessToken(requestParameters: AccessTokenApiUpdateAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccessToken> {
                    const response = await this.updateAccessTokenRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
