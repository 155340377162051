import React from "react";
import { Grid, Heading, HStack } from "@chakra-ui/react";
import { CopyButton, Paper, PlaceholderText, TextColumn } from "@cobira/ui-library";
import { Device } from "../../../api";

export interface DeviceHardwareDetailsPaperProps {
    device?: Device;
}

const DeviceHardwareDetailsPaper = ({ device }: DeviceHardwareDetailsPaperProps) => {
    return (
        <Paper header={"Hardware Details"} withDivider>
            <Grid templateColumns="auto auto" gap="4">
                <TextColumn
                    heading={
                        <HStack>
                            <Heading size={"sm"}>Name</Heading>
                            <CopyButton value={device?.name || ""} />
                        </HStack>
                    }
                    grid
                >
                    <PlaceholderText text={device?.name} />
                </TextColumn>
                <TextColumn
                    heading={
                        <HStack>
                            <Heading size={"sm"}>Identifier</Heading>
                            <CopyButton value={device?.identifier || ""} />
                        </HStack>
                    }
                    grid
                >
                    <PlaceholderText text={device?.identifier} />
                </TextColumn>
                <TextColumn heading="Manufacturer" grid>
                    <PlaceholderText text={device?.manufacturer} />
                </TextColumn>
                <TextColumn heading="Product Code" grid>
                    <PlaceholderText text={device?.productCode} />
                </TextColumn>
                <TextColumn heading="Hardware Revision" grid>
                    <PlaceholderText text={device?.hardwareRevision} />
                </TextColumn>
                <TextColumn heading="Firmware version" grid>
                    <PlaceholderText text={device?.firmware} />
                </TextColumn>
            </Grid>
        </Paper>
    );
};

export default DeviceHardwareDetailsPaper;
