import React from "react";
import { getHumanizedDurationSinceDateString, IndicatorLabel, InfoPopover } from "@cobira/ui-library";
import { Box } from "@chakra-ui/react";

export interface DeviceLanHostStateProps {
    fetchDate?: Date;
    connected?: boolean;
}

const DeviceLanHostState = ({ connected, fetchDate }: DeviceLanHostStateProps) => {
    const lastUpdated = fetchDate
        ? getHumanizedDurationSinceDateString(fetchDate, {
              units: ["d", "h", "m"],
              justNowSeconds: 30,
              justNowTemplate: "A few moments ago",
              timeAgoTemplate: "{duration} ago",
          })
        : "N/A";
    if (connected) {
        return (
            <InfoPopover value={lastUpdated}>
                <Box>
                    <IndicatorLabel text={"Connected"} color={"green"} />
                </Box>
            </InfoPopover>
        );
    } else {
        return (
            <InfoPopover value={lastUpdated}>
                <IndicatorLabel text={"Not Connected"} color={"red"} />
            </InfoPopover>
        );
    }
};

export default DeviceLanHostState;
