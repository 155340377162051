import React from "react";
import { LoadingSkeleton, PlaceholderText, QueryCell } from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import NetworkCredentialTypesCell from "../NetworkCredentialTypesCell/NetworkCredentialTypesCell";

export interface SimCardNetworkCredentialTypesCellProps {
    icc?: string;
}

const SimCardNetworkCredentialTypesCell = ({ icc }: SimCardNetworkCredentialTypesCellProps) => {
    const { simCardApi } = useApi();

    if (!icc) {
        return <PlaceholderText text={null} />;
    }

    return (
        <QueryCell
            loadingComponent={<LoadingSkeleton />}
            query={{
                queryFn: () => simCardApi.getSimCardNetworkCredentials({ icc: icc }),
                queryKey: ["simcards", icc, "networkcredentials"],
            }}
            render={result => (
                <NetworkCredentialTypesCell
                    networkCredentialTypeIds={
                        result?.data?.map(networkCredential => networkCredential.networkCredentialTypeId || "") || []
                    }
                />
            )}
        />
    );
};

export default SimCardNetworkCredentialTypesCell;
