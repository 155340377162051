/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RadioTechnology } from './RadioTechnology';
import {
    RadioTechnologyFromJSON,
    RadioTechnologyFromJSONTyped,
    RadioTechnologyToJSON,
} from './RadioTechnology';

/**
 * 
 * @export
 * @interface SimCardNetworkStateRadioTechnology
 */
export interface SimCardNetworkStateRadioTechnology {
    /**
     * 
     * @type {RadioTechnology}
     * @memberof SimCardNetworkStateRadioTechnology
     */
    radioTechnology?: RadioTechnology;
    /**
     * 
     * @type {Date}
     * @memberof SimCardNetworkStateRadioTechnology
     */
    recordedAt?: Date;
}

/**
 * Check if a given object implements the SimCardNetworkStateRadioTechnology interface.
 */
export function instanceOfSimCardNetworkStateRadioTechnology(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SimCardNetworkStateRadioTechnologyFromJSON(json: any): SimCardNetworkStateRadioTechnology {
    return SimCardNetworkStateRadioTechnologyFromJSONTyped(json, false);
}

export function SimCardNetworkStateRadioTechnologyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardNetworkStateRadioTechnology {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'radioTechnology': !exists(json, 'radioTechnology') ? undefined : RadioTechnologyFromJSON(json['radioTechnology']),
        'recordedAt': !exists(json, 'recordedAt') ? undefined : (new Date(json['recordedAt'])),
    };
}

export function SimCardNetworkStateRadioTechnologyToJSON(value?: SimCardNetworkStateRadioTechnology | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'radioTechnology': RadioTechnologyToJSON(value.radioTechnology),
        'recordedAt': value.recordedAt === undefined ? undefined : (value.recordedAt.toISOString()),
    };
}

