/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActionActor } from './ActionActor';
import {
    ActionActorFromJSON,
    ActionActorFromJSONTyped,
    ActionActorToJSON,
} from './ActionActor';
import type { ActionClassifier } from './ActionClassifier';
import {
    ActionClassifierFromJSON,
    ActionClassifierFromJSONTyped,
    ActionClassifierToJSON,
} from './ActionClassifier';
import type { ActionItemState } from './ActionItemState';
import {
    ActionItemStateFromJSON,
    ActionItemStateFromJSONTyped,
    ActionItemStateToJSON,
} from './ActionItemState';
import type { ActionType } from './ActionType';
import {
    ActionTypeFromJSON,
    ActionTypeFromJSONTyped,
    ActionTypeToJSON,
} from './ActionType';

/**
 * 
 * @export
 * @interface SimCardActionItem
 */
export interface SimCardActionItem {
    /**
     * 
     * @type {string}
     * @memberof SimCardActionItem
     */
    actionId: string;
    /**
     * 
     * @type {ActionType}
     * @memberof SimCardActionItem
     */
    actionType: ActionType;
    /**
     * 
     * @type {ActionClassifier}
     * @memberof SimCardActionItem
     */
    actionClassifier?: ActionClassifier;
    /**
     * 
     * @type {ActionItemState}
     * @memberof SimCardActionItem
     */
    state: ActionItemState;
    /**
     * 
     * @type {string}
     * @memberof SimCardActionItem
     */
    stateMessage?: string;
    /**
     * 
     * @type {Date}
     * @memberof SimCardActionItem
     */
    processedAt: Date;
    /**
     * 
     * @type {ActionActor}
     * @memberof SimCardActionItem
     */
    actor?: ActionActor;
}

/**
 * Check if a given object implements the SimCardActionItem interface.
 */
export function instanceOfSimCardActionItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "actionId" in value;
    isInstance = isInstance && "actionType" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "processedAt" in value;

    return isInstance;
}

export function SimCardActionItemFromJSON(json: any): SimCardActionItem {
    return SimCardActionItemFromJSONTyped(json, false);
}

export function SimCardActionItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimCardActionItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actionId': json['actionId'],
        'actionType': ActionTypeFromJSON(json['actionType']),
        'actionClassifier': !exists(json, 'actionClassifier') ? undefined : ActionClassifierFromJSON(json['actionClassifier']),
        'state': ActionItemStateFromJSON(json['state']),
        'stateMessage': !exists(json, 'stateMessage') ? undefined : json['stateMessage'],
        'processedAt': (new Date(json['processedAt'])),
        'actor': !exists(json, 'actor') ? undefined : ActionActorFromJSON(json['actor']),
    };
}

export function SimCardActionItemToJSON(value?: SimCardActionItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actionId': value.actionId,
        'actionType': ActionTypeToJSON(value.actionType),
        'actionClassifier': ActionClassifierToJSON(value.actionClassifier),
        'state': ActionItemStateToJSON(value.state),
        'stateMessage': value.stateMessage,
        'processedAt': (value.processedAt.toISOString()),
        'actor': ActionActorToJSON(value.actor),
    };
}

