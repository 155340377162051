import { useApi } from "./useApi";
import { useQuery } from "@tanstack/react-query";
import { ActionActor, DeviceACLMode, DeviceLanHost } from "../api";
import { useMemo } from "react";

export type LanHostAndACL = DeviceLanHost & {
    note?: string;
    aclMode?: DeviceACLMode;
    updatedBy?: ActionActor;
    configured: boolean;
};

export interface UseLanHostACLAggregationsProps {
    deviceId?: string;
}

export const useLanHostACLAggregations = ({ deviceId }: UseLanHostACLAggregationsProps) => {
    const { deviceApi } = useApi();

    const { data: lanHosts, isFetching: isLoadingLanHosts } = useQuery(
        ["devices", deviceId, "lan", "hosts"],
        () => deviceApi.getDeviceLanHosts({ deviceId: deviceId || "" }),
        {
            enabled: !!deviceId,
        },
    );

    const { data: deviceACL, isFetching: isLoadingACL } = useQuery(
        ["devices", deviceId, "acl"],
        () => deviceApi.getDeviceAclConfiguration({ deviceId: deviceId || "" }),
        { enabled: !!deviceId },
    );

    return useMemo(() => {
        const portGrouping = new Map<string, LanHostAndACL[]>();
        const mergedLanHosts = lanHosts?.map(lanHost => {
            const aclRule = deviceACL?.aclEntries.find(aclEntry => aclEntry.lanHostId === lanHost.id);
            return {
                ...lanHost,
                aclMode: deviceACL?.aclMode,
                updatedBy: deviceACL?.updatedBy,
                note: aclRule?.note,
                configured: !!aclRule,
            };
        });
        if (mergedLanHosts) {
            mergedLanHosts.forEach(value => {
                if (!portGrouping.has(value.portId)) {
                    portGrouping.set(value.portId, []);
                }

                portGrouping.get(value.portId)?.push(value);
            });
        }

        return {
            lanHosts: mergedLanHosts,
            portGroupedLanHosts: portGrouping,
            isLoading: isLoadingLanHosts || isLoadingACL,
        };
    }, [deviceACL, isLoadingACL, isLoadingLanHosts, lanHosts]);
};
