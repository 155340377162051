/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Page properties for use in other concrete Page models.
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    totalPages?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    first?: boolean;
}

/**
 * Check if a given object implements the Page interface.
 */
export function instanceOfPage(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PageFromJSON(json: any): Page {
    return PageFromJSONTyped(json, false);
}

export function PageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Page {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'totalElements': !exists(json, 'totalElements') ? undefined : json['totalElements'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'numberOfElements': !exists(json, 'numberOfElements') ? undefined : json['numberOfElements'],
        'first': !exists(json, 'first') ? undefined : json['first'],
    };
}

export function PageToJSON(value?: Page | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalPages': value.totalPages,
        'last': value.last,
        'totalElements': value.totalElements,
        'size': value.size,
        'number': value.number,
        'numberOfElements': value.numberOfElements,
        'first': value.first,
    };
}

