import React, { ReactNode } from "react";
import { SimCardNetworkState } from "../../../api";
import { PlaceholderText } from "@cobira/ui-library";
import { RadioTechnologyLabels } from "../../../labels/RadioTechnologyLabels";

interface NetworkStateRadioTechnologyProps {
    networkState?: SimCardNetworkState;
    loadingComponent?: ReactNode;
    placeholderComponent?: ReactNode;
}

export const NetworkStateRadioTechnology = ({
    networkState,
    placeholderComponent,
}: NetworkStateRadioTechnologyProps) => {
    if (!networkState?.radioTechnology) {
        return <>{placeholderComponent}</>;
    }

    return <PlaceholderText text={RadioTechnologyLabels(networkState?.radioTechnology?.radioTechnology)} />;
};
