/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        CreateWebhookSubscription,
        WebhookSubscription,
        WebhookSubscriptionPage,
    } from '../models';
        import {
            CreateWebhookSubscriptionFromJSON,
            CreateWebhookSubscriptionToJSON,
            WebhookSubscriptionFromJSON,
            WebhookSubscriptionToJSON,
            WebhookSubscriptionPageFromJSON,
            WebhookSubscriptionPageToJSON,
        } from '../models';

            export interface WebhookApiCreateWebhookSubscriptionRequest {
                createWebhookSubscription: CreateWebhookSubscription;
            }

            export interface WebhookApiDeleteWebhookSubscriptionRequest {
                webhookSubscriptionId: string;
            }

            export interface WebhookApiGetWebhookSubscriptionRequest {
                webhookSubscriptionId: string;
            }

            export interface WebhookApiGetWebhookSubscriptionsRequest {
                pageNumber: number;
                pageSize: number;
            }

        /**
        * 
        */
            export class WebhookApi extends runtime.BaseAPI {

            /**
                * Create a new webhook subscription.
                * Create webhook subscription
            */
            async createWebhookSubscriptionRaw(requestParameters: WebhookApiCreateWebhookSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebhookSubscription>> {
                    if (requestParameters.createWebhookSubscription === null || requestParameters.createWebhookSubscription === undefined) {
                    throw new runtime.RequiredError('createWebhookSubscription','Required parameter requestParameters.createWebhookSubscription was null or undefined when calling createWebhookSubscription.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/webhooksubscriptions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: CreateWebhookSubscriptionToJSON(requestParameters.createWebhookSubscription),
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => WebhookSubscriptionFromJSON(jsonValue));
            }

            /**
                * Create a new webhook subscription.
                * Create webhook subscription
            */
                async createWebhookSubscription(requestParameters: WebhookApiCreateWebhookSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebhookSubscription> {
                    const response = await this.createWebhookSubscriptionRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Delete an existing webhook subscription.  Webhooks already queued at the time of deletion will still be delivered according to the retry policy.
                * Delete webhook subscription
            */
            async deleteWebhookSubscriptionRaw(requestParameters: WebhookApiDeleteWebhookSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
                    if (requestParameters.webhookSubscriptionId === null || requestParameters.webhookSubscriptionId === undefined) {
                    throw new runtime.RequiredError('webhookSubscriptionId','Required parameter requestParameters.webhookSubscriptionId was null or undefined when calling deleteWebhookSubscription.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/webhooksubscriptions/{webhookSubscriptionId}`.replace(`{${"webhookSubscriptionId"}}`, encodeURIComponent(String(requestParameters.webhookSubscriptionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                return new runtime.VoidApiResponse(response);
            }

            /**
                * Delete an existing webhook subscription.  Webhooks already queued at the time of deletion will still be delivered according to the retry policy.
                * Delete webhook subscription
            */
                async deleteWebhookSubscription(requestParameters: WebhookApiDeleteWebhookSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
                    await this.deleteWebhookSubscriptionRaw(requestParameters, initOverrides);
                }

            /**
                * Get a configured webhook subscription using its id.
                * Get webhook subscription
            */
            async getWebhookSubscriptionRaw(requestParameters: WebhookApiGetWebhookSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebhookSubscription>> {
                    if (requestParameters.webhookSubscriptionId === null || requestParameters.webhookSubscriptionId === undefined) {
                    throw new runtime.RequiredError('webhookSubscriptionId','Required parameter requestParameters.webhookSubscriptionId was null or undefined when calling getWebhookSubscription.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/webhooksubscriptions/{webhookSubscriptionId}`.replace(`{${"webhookSubscriptionId"}}`, encodeURIComponent(String(requestParameters.webhookSubscriptionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => WebhookSubscriptionFromJSON(jsonValue));
            }

            /**
                * Get a configured webhook subscription using its id.
                * Get webhook subscription
            */
                async getWebhookSubscription(requestParameters: WebhookApiGetWebhookSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebhookSubscription> {
                    const response = await this.getWebhookSubscriptionRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all configured webhook subscriptions.
                * Get webhook subscriptions
            */
            async getWebhookSubscriptionsRaw(requestParameters: WebhookApiGetWebhookSubscriptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebhookSubscriptionPage>> {
                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getWebhookSubscriptions.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getWebhookSubscriptions.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/webhooksubscriptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => WebhookSubscriptionPageFromJSON(jsonValue));
            }

            /**
                * Get all configured webhook subscriptions.
                * Get webhook subscriptions
            */
                async getWebhookSubscriptions(requestParameters: WebhookApiGetWebhookSubscriptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebhookSubscriptionPage> {
                    const response = await this.getWebhookSubscriptionsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
