import React, { useState } from "react";
import {
    ChevronDownIcon,
    CobiraTabs,
    HasPermission,
    Page,
    PageBackButton,
    PageTitle,
    usePageNavigation,
} from "@cobira/ui-library";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useApi } from "../../../hooks/useApi";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import DeviceDetailsPanel from "./DeviceDetailPanel/DeviceDetailPanel";
import DeviceDataSessionsPanel from "./DeviceDataSessionsPanel/DeviceDataSessionsPanel";
import DeviceActionModals, { DeviceActionType } from "../../../components/Modal/DeviceActionModals/DeviceActionModals";
import { Device, DeviceActionRequestFilter, SimCardActionRequestFilter } from "../../../api";
import DeviceLanHostsPanel from "./DeviceLanHostsPanel/DeviceLanHostsPanel";
import DeviceActivityPanel from "./DeviceActivityPanel/DeviceActivityPanel";
import SimCardActionModals, { SimActionType } from "../../../components/Modal/SimCardActionModals/SimCardActionModals";
import { Permission } from "../../../utils/Permissions";
import RestrictedPage from "../../../components/RestrictedPage/RestrictedPage";

const DeviceDetailPage = () => {
    const { deviceApi, simCardApi } = useApi();
    const { deviceId } = useParams();
    const [chosenOperation, setChosenOperation] = useState<DeviceActionType | null>(null);
    const [chosenSimOperation, setChosenSimOperation] = useState<SimActionType | null>(null);
    const { navigate } = usePageNavigation<Device>({ route: value => `/devices/${value.id}/acl/update` });
    const deviceQuery = useQuery(["devices", deviceId], () => deviceApi.getDevice({ deviceId: deviceId || "" }), {
        enabled: !!deviceId,
    });
    const simCardQuery = useQuery(
        ["simcards", deviceQuery?.data?.icc],
        () => simCardApi.getSimCardByIcc({ icc: deviceQuery?.data?.icc || "" }),
        {
            enabled: !!deviceQuery?.data?.icc,
        },
    );
    const networkStateQuery = useQuery(
        ["simcards", deviceQuery?.data?.icc, "networkstate"],
        () => simCardApi.getSimCardNetworkState({ icc: deviceQuery?.data?.icc || "" }),
        {
            enabled: !!deviceQuery?.data?.icc,
        },
    );

    const deviceActionFilter: DeviceActionRequestFilter = {
        deviceIdSet: new Set<string>([deviceId as string]),
    };
    const simActionFilter: SimCardActionRequestFilter = {
        iccSet: simCardQuery.data?.icc ? new Set<string>([simCardQuery.data?.icc as string]) : new Set(),
    };

    const deviceDetailTabs = [
        {
            title: "Details",
            content: (
                <DeviceDetailsPanel
                    deviceQuery={deviceQuery}
                    simCardQuery={simCardQuery}
                    networkStateQuery={networkStateQuery}
                />
            ),
        },
        {
            title: "Data Sessions",
            content: <DeviceDataSessionsPanel simCardQuery={simCardQuery} />,
        },
        {
            title: "LAN Hosts",
            content: <DeviceLanHostsPanel deviceQuery={deviceQuery} />,
        },
        {
            title: "Activity",
            content: <DeviceActivityPanel deviceQuery={deviceQuery} />,
        },
    ];

    return (
        <Page
            title={<PageTitle title={deviceQuery?.data?.name || "Device Details"} />}
            navigation={<PageBackButton text={"Back to devices"} path={"/devices"} />}
            permissions={{
                values: [Permission.device.read],
                deniedComponent: <RestrictedPage />,
            }}
            pageContext={
                <HasPermission values={[Permission.device.write, Permission.simCard.write]} type={"anyOf"}>
                    <Menu>
                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                            Manage
                        </MenuButton>
                        <MenuList>
                            <HasPermission values={[Permission.device.write]}>
                                <MenuItem onClick={() => setChosenOperation("reboot")}>Reboot</MenuItem>
                                <MenuItem onClick={() => setChosenOperation("discover-host")}>
                                    Refresh Host Table
                                </MenuItem>
                                {deviceQuery.data && (
                                    <MenuItem onClick={() => navigate(deviceQuery.data)}>Update ACL</MenuItem>
                                )}
                            </HasPermission>
                            <HasPermission values={[Permission.simCard.write]}>
                                {simCardQuery.data && (
                                    <MenuItem onClick={() => setChosenSimOperation("reset")}>Reset SIM Card</MenuItem>
                                )}
                            </HasPermission>
                        </MenuList>
                    </Menu>
                </HasPermission>
            }
        >
            <CobiraTabs tabs={deviceDetailTabs} />
            <DeviceActionModals
                actionType={chosenOperation}
                filter={deviceActionFilter}
                onClose={() => setChosenOperation(null)}
            />
            <SimCardActionModals
                actionType={chosenSimOperation}
                filter={simActionFilter}
                onClose={() => setChosenSimOperation(null)}
            />
        </Page>
    );
};

export default DeviceDetailPage;
