/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        Action,
        Device,
        DeviceACL,
        DeviceACLUpdateRequest,
        DeviceConnectionState,
        DeviceHostDiscoveryRequest,
        DeviceLanHost,
        DevicePage,
        DeviceRebootRequest,
    } from '../models';
        import {
            ActionFromJSON,
            ActionToJSON,
            DeviceFromJSON,
            DeviceToJSON,
            DeviceACLFromJSON,
            DeviceACLToJSON,
            DeviceACLUpdateRequestFromJSON,
            DeviceACLUpdateRequestToJSON,
            DeviceConnectionStateFromJSON,
            DeviceConnectionStateToJSON,
            DeviceHostDiscoveryRequestFromJSON,
            DeviceHostDiscoveryRequestToJSON,
            DeviceLanHostFromJSON,
            DeviceLanHostToJSON,
            DevicePageFromJSON,
            DevicePageToJSON,
            DeviceRebootRequestFromJSON,
            DeviceRebootRequestToJSON,
        } from '../models';

            export interface DeviceApiDiscoverConnectedHostsRequest {
                deviceHostDiscoveryRequest: DeviceHostDiscoveryRequest;
            }

            export interface DeviceApiGetDeviceRequest {
                deviceId: string;
            }

            export interface DeviceApiGetDeviceAclConfigurationRequest {
                deviceId: string;
            }

            export interface DeviceApiGetDeviceLanHostsRequest {
                deviceId: string;
            }

            export interface DeviceApiGetDevicesRequest {
                pageNumber: number;
                pageSize: number;
                icc?: string;
                identifier?: string;
                search?: string;
                model?: string;
                deviceConnectionState?: DeviceConnectionState;
            }

            export interface DeviceApiRebootDevicesRequest {
                deviceRebootRequest: DeviceRebootRequest;
            }

            export interface DeviceApiUpdateDeviceAclRequest {
                deviceACLUpdateRequest: DeviceACLUpdateRequest;
            }

        /**
        * 
        */
            export class DeviceApi extends runtime.BaseAPI {

            /**
                * Schedules a host discovery operation for all the requested devices.  The connected hosts will be reported separately, but the progress of the discovery process can be followed through the return action handle.
                * Requests a connected hosts discovery operation on the selected devices.
            */
            async discoverConnectedHostsRaw(requestParameters: DeviceApiDiscoverConnectedHostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Action>>> {
                    if (requestParameters.deviceHostDiscoveryRequest === null || requestParameters.deviceHostDiscoveryRequest === undefined) {
                    throw new runtime.RequiredError('deviceHostDiscoveryRequest','Required parameter requestParameters.deviceHostDiscoveryRequest was null or undefined when calling discoverConnectedHosts.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/actions/devices/discoverhosts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: DeviceHostDiscoveryRequestToJSON(requestParameters.deviceHostDiscoveryRequest),
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionFromJSON));
            }

            /**
                * Schedules a host discovery operation for all the requested devices.  The connected hosts will be reported separately, but the progress of the discovery process can be followed through the return action handle.
                * Requests a connected hosts discovery operation on the selected devices.
            */
                async discoverConnectedHosts(requestParameters: DeviceApiDiscoverConnectedHostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Action>> {
                    const response = await this.discoverConnectedHostsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all devices assigned to your account
                * Get device details
            */
            async getDeviceRaw(requestParameters: DeviceApiGetDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Device>> {
                    if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
                    throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling getDevice.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/devices/{deviceId}`.replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => DeviceFromJSON(jsonValue));
            }

            /**
                * Get all devices assigned to your account
                * Get device details
            */
                async getDevice(requestParameters: DeviceApiGetDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Device> {
                    const response = await this.getDeviceRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get complete ACL configuration for a device
                * Get device ACL configuration
            */
            async getDeviceAclConfigurationRaw(requestParameters: DeviceApiGetDeviceAclConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeviceACL>> {
                    if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
                    throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling getDeviceAclConfiguration.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/devices/{deviceId}/acl`.replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => DeviceACLFromJSON(jsonValue));
            }

            /**
                * Get complete ACL configuration for a device
                * Get device ACL configuration
            */
                async getDeviceAclConfiguration(requestParameters: DeviceApiGetDeviceAclConfigurationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeviceACL> {
                    const response = await this.getDeviceAclConfigurationRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all hosts discovered on the LAN for the device
                * Get device LAN hosts
            */
            async getDeviceLanHostsRaw(requestParameters: DeviceApiGetDeviceLanHostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DeviceLanHost>>> {
                    if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
                    throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling getDeviceLanHosts.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/devices/{deviceId}/lan/hosts`.replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeviceLanHostFromJSON));
            }

            /**
                * Get all hosts discovered on the LAN for the device
                * Get device LAN hosts
            */
                async getDeviceLanHosts(requestParameters: DeviceApiGetDeviceLanHostsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DeviceLanHost>> {
                    const response = await this.getDeviceLanHostsRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all devices assigned to your account
                * Get all devices
            */
            async getDevicesRaw(requestParameters: DeviceApiGetDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DevicePage>> {
                    if (requestParameters.pageNumber === null || requestParameters.pageNumber === undefined) {
                    throw new runtime.RequiredError('pageNumber','Required parameter requestParameters.pageNumber was null or undefined when calling getDevices.');
                    }

                    if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
                    throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getDevices.');
                    }

            const queryParameters: any = {};

                    if (requestParameters.pageNumber !== undefined) {
                            queryParameters['pageNumber'] = requestParameters.pageNumber;
                    }

                    if (requestParameters.pageSize !== undefined) {
                            queryParameters['pageSize'] = requestParameters.pageSize;
                    }

                    if (requestParameters.icc !== undefined) {
                            queryParameters['icc'] = requestParameters.icc;
                    }

                    if (requestParameters.identifier !== undefined) {
                            queryParameters['identifier'] = requestParameters.identifier;
                    }

                    if (requestParameters.search !== undefined) {
                            queryParameters['search'] = requestParameters.search;
                    }

                    if (requestParameters.model !== undefined) {
                            queryParameters['model'] = requestParameters.model;
                    }

                    if (requestParameters.deviceConnectionState !== undefined) {
                            queryParameters['deviceConnectionState'] = requestParameters.deviceConnectionState;
                    }

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/devices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => DevicePageFromJSON(jsonValue));
            }

            /**
                * Get all devices assigned to your account
                * Get all devices
            */
                async getDevices(requestParameters: DeviceApiGetDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DevicePage> {
                    const response = await this.getDevicesRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Schedules a reboot of the devices specified in the request through the filter.
                * Request a reboot of the selected devices
            */
            async rebootDevicesRaw(requestParameters: DeviceApiRebootDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Action>>> {
                    if (requestParameters.deviceRebootRequest === null || requestParameters.deviceRebootRequest === undefined) {
                    throw new runtime.RequiredError('deviceRebootRequest','Required parameter requestParameters.deviceRebootRequest was null or undefined when calling rebootDevices.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/actions/devices/reboot`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: DeviceRebootRequestToJSON(requestParameters.deviceRebootRequest),
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionFromJSON));
            }

            /**
                * Schedules a reboot of the devices specified in the request through the filter.
                * Request a reboot of the selected devices
            */
                async rebootDevices(requestParameters: DeviceApiRebootDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Action>> {
                    const response = await this.rebootDevicesRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Create or replace the current ACL configuration of a managed device. Any existing ACL configuration will be replaced with the new supplied configuration.
                * Update the ACL configuration of the specified devices.
            */
            async updateDeviceAclRaw(requestParameters: DeviceApiUpdateDeviceAclRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Action>>> {
                    if (requestParameters.deviceACLUpdateRequest === null || requestParameters.deviceACLUpdateRequest === undefined) {
                    throw new runtime.RequiredError('deviceACLUpdateRequest','Required parameter requestParameters.deviceACLUpdateRequest was null or undefined when calling updateDeviceAcl.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                    headerParameters['Content-Type'] = 'application/json';

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/actions/devices/updateacl`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
                                body: DeviceACLUpdateRequestToJSON(requestParameters.deviceACLUpdateRequest),
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ActionFromJSON));
            }

            /**
                * Create or replace the current ACL configuration of a managed device. Any existing ACL configuration will be replaced with the new supplied configuration.
                * Update the ACL configuration of the specified devices.
            */
                async updateDeviceAcl(requestParameters: DeviceApiUpdateDeviceAclRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Action>> {
                    const response = await this.updateDeviceAclRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
