import React, { useEffect, useState } from "react";
import { Heading, HStack, TabPanel, VStack } from "@chakra-ui/react";
import { NetworkCredential, SimCard } from "../../../../api";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../../../hooks/useApi";
import { CobiraTable, NetworkStateIndicator, Paper, PlaceholderText, TextColumn } from "@cobira/ui-library";
import NetworkCredentialDetails from "./NetworkCredentialDetails";
import { createColumnHelper } from "@tanstack/react-table";
import { NetworkCredentialTypeCell } from "../../../../components/Cells/NetworkCredentialTypeCell/NetworkCredentialTypeCell";

export interface SimCardNetworkCredentialsPanelProps {
    query: UseQueryResult<SimCard>;
}

const networkCredentialColumn = createColumnHelper<NetworkCredential>();
const NETWORK_CREDENTIAL_COLUMNS = [
    networkCredentialColumn.accessor("networkState", {
        id: "state",
        header: "State",
        cell: row => <NetworkStateIndicator state={row.getValue()} withLabel />,
    }),
    networkCredentialColumn.accessor("imsi", {
        id: "imsi",
        header: "IMSI",
        cell: row => row.getValue(),
    }),
    networkCredentialColumn.accessor("networkCredentialTypeId", {
        id: "networkCredentialType",
        header: "Type",
        cell: row => <NetworkCredentialTypeCell networkCredentialTypeId={row.getValue()} showPlmn={false} />,
    }),
];

const SimCardNetworkCredentialsPanel = ({ query: { data: simCard } }: SimCardNetworkCredentialsPanelProps) => {
    const { simCardApi } = useApi();
    const [selectedNetworkCredential, setSelectedNetworkCredential] = useState<NetworkCredential | undefined>(
        undefined,
    );

    const simConfigurationQuery = useQuery(
        ["simconfigurations", simCard?.simConfigurationId],
        () => simCardApi.getSimConfiguration({ simConfigurationId: simCard?.simConfigurationId || "" }),
        { enabled: !!simCard?.simConfigurationId },
    );

    const { data: networkCredentials, isLoading: isLoadingNetworkCredentials } = useQuery(
        ["simcards", simCard?.icc, "networkcredentials"],
        () => simCardApi.getSimCardNetworkCredentials({ icc: simCard?.icc || "" }),
        { enabled: !!simCard?.icc },
    );

    useEffect(() => {
        if (networkCredentials && networkCredentials.length && !selectedNetworkCredential) {
            setSelectedNetworkCredential(networkCredentials[0]);
        }
    }, [networkCredentials, selectedNetworkCredential, setSelectedNetworkCredential]);

    return (
        <TabPanel>
            <VStack gap={6} align={"stretch"}>
                <HStack w={"100%"} align={"stretch"} gap={4}>
                    <Paper header={"SIM Configuration"} withDivider cardProps={{ w: "35%" }}>
                        <TextColumn heading={"Name"} isLoading={simConfigurationQuery?.isLoading}>
                            <PlaceholderText text={simConfigurationQuery?.data?.name} />
                        </TextColumn>
                    </Paper>
                    <VStack w={"100%"}>
                        <Heading size={"md"} alignSelf={"start"}>
                            Network Credentials
                        </Heading>
                        <CobiraTable
                            withRowClick={{
                                enableHoverStyle: true,
                                clickedRow: selectedNetworkCredential,
                                onRowClicked: setSelectedNetworkCredential,
                            }}
                            columns={NETWORK_CREDENTIAL_COLUMNS}
                            data={networkCredentials || []}
                            isLoading={isLoadingNetworkCredentials}
                        />
                    </VStack>
                </HStack>
                <NetworkCredentialDetails networkCredential={selectedNetworkCredential} simCard={simCard} />
            </VStack>
        </TabPanel>
    );
};

export default SimCardNetworkCredentialsPanel;
