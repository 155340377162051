/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Dimension = {
    Unknown: 'UNKNOWN',
    Country: 'COUNTRY',
    NetworkPlmn: 'NETWORK_PLMN'
} as const;
export type Dimension = typeof Dimension[keyof typeof Dimension];


export function DimensionFromJSON(json: any): Dimension {
    return DimensionFromJSONTyped(json, false);
}

export function DimensionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Dimension {
    return json as Dimension;
}

export function DimensionToJSON(value?: Dimension | null): any {
    return value as any;
}

