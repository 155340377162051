/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataSession } from './DataSession';
import {
    DataSessionFromJSON,
    DataSessionFromJSONTyped,
    DataSessionToJSON,
} from './DataSession';
import type { Page } from './Page';
import {
    PageFromJSON,
    PageFromJSONTyped,
    PageToJSON,
} from './Page';

/**
 * 
 * @export
 * @interface DataSessionPage
 */
export interface DataSessionPage {
    /**
     * 
     * @type {Page}
     * @memberof DataSessionPage
     */
    pageProperties: Page;
    /**
     * 
     * @type {Array<DataSession>}
     * @memberof DataSessionPage
     */
    content: Array<DataSession>;
}

/**
 * Check if a given object implements the DataSessionPage interface.
 */
export function instanceOfDataSessionPage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pageProperties" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function DataSessionPageFromJSON(json: any): DataSessionPage {
    return DataSessionPageFromJSONTyped(json, false);
}

export function DataSessionPageFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSessionPage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageProperties': PageFromJSON(json['pageProperties']),
        'content': ((json['content'] as Array<any>).map(DataSessionFromJSON)),
    };
}

export function DataSessionPageToJSON(value?: DataSessionPage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageProperties': PageToJSON(value.pageProperties),
        'content': ((value.content as Array<any>).map(DataSessionToJSON)),
    };
}

