import React, { ReactNode } from "react";
import { SimCardNetworkState } from "../../../api";
import { Flag, PlaceholderText } from "@cobira/ui-library";

interface NetworkStateNetworkInfoProps {
    networkState?: SimCardNetworkState;
    placeholderComponent?: ReactNode;
}

export const NetworkStateCountry = ({ networkState, placeholderComponent }: NetworkStateNetworkInfoProps) => {
    if (!networkState?.network) {
        return <>{placeholderComponent}</>;
    }
    if (networkState?.network.country && networkState?.network.country !== "UNDEFINED") {
        return <Flag countryCode={networkState?.network?.country} />;
    } else {
        return <PlaceholderText text={undefined} />;
    }
};
