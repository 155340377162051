/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
    import type {
        AccessToken,
        Integration,
    } from '../models';
        import {
            AccessTokenFromJSON,
            AccessTokenToJSON,
            IntegrationFromJSON,
            IntegrationToJSON,
        } from '../models';

            export interface IntegrationApiGetAccessTokenRequest {
                integrationId: string;
                accessTokenId: string;
            }

            export interface IntegrationApiGetIntegrationRequest {
                integrationId: string;
            }

            export interface IntegrationApiRollIntegrationSecretRequest {
                integrationId: string;
            }

        /**
        * 
        */
            export class IntegrationApi extends runtime.BaseAPI {

            /**
                * Get a specific access token by id.
                * Get access token
            */
            async getAccessTokenRaw(requestParameters: IntegrationApiGetAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccessToken>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling getAccessToken.');
                    }

                    if (requestParameters.accessTokenId === null || requestParameters.accessTokenId === undefined) {
                    throw new runtime.RequiredError('accessTokenId','Required parameter requestParameters.accessTokenId was null or undefined when calling getAccessToken.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}/accesstokens/{accessTokenId}`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))).replace(`{${"accessTokenId"}}`, encodeURIComponent(String(requestParameters.accessTokenId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => AccessTokenFromJSON(jsonValue));
            }

            /**
                * Get a specific access token by id.
                * Get access token
            */
                async getAccessToken(requestParameters: IntegrationApiGetAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccessToken> {
                    const response = await this.getAccessTokenRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get a specific integration by its id.
                * Get integration
            */
            async getIntegrationRaw(requestParameters: IntegrationApiGetIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Integration>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling getIntegration.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationFromJSON(jsonValue));
            }

            /**
                * Get a specific integration by its id.
                * Get integration
            */
                async getIntegration(requestParameters: IntegrationApiGetIntegrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Integration> {
                    const response = await this.getIntegrationRaw(requestParameters, initOverrides);
                    return await response.value();
                }

            /**
                * Get all configured integrations.  Currently only a single integration will be available, but more integrations may be supported in the future.
                * Get integrations
            */
            async getIntegrationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Integration>>> {
            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(IntegrationFromJSON));
            }

            /**
                * Get all configured integrations.  Currently only a single integration will be available, but more integrations may be supported in the future.
                * Get integrations
            */
                async getIntegrations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Integration>> {
                    const response = await this.getIntegrationsRaw(initOverrides);
                    return await response.value();
                }

            /**
                * Rolls the integration shared secret used to compute the signature for emitted webhooks.  Webhooks that were already scheduled for delivery will continue to use the old secret until successfully delivered, or they failed.
                * Roll shared secret
            */
            async rollIntegrationSecretRaw(requestParameters: IntegrationApiRollIntegrationSecretRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Integration>> {
                    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
                    throw new runtime.RequiredError('integrationId','Required parameter requestParameters.integrationId was null or undefined when calling rollIntegrationSecret.');
                    }

            const queryParameters: any = {};

            const headerParameters: runtime.HTTPHeaders = {};

                        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
                        headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
                        }
                        if (this.configuration && this.configuration.accessToken) {
                        const token = this.configuration.accessToken;
                        const tokenString = await token("bearer", []);

                        if (tokenString) {
                        headerParameters["Authorization"] = `Bearer ${tokenString}`;
                        }
                        }
            const response = await this.request({
            path: `/integrations/{integrationId}/rollSecret`.replace(`{${"integrationId"}}`, encodeURIComponent(String(requestParameters.integrationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            }, initOverrides);

                                return new runtime.JSONApiResponse(response, (jsonValue) => IntegrationFromJSON(jsonValue));
            }

            /**
                * Rolls the integration shared secret used to compute the signature for emitted webhooks.  Webhooks that were already scheduled for delivery will continue to use the old secret until successfully delivered, or they failed.
                * Roll shared secret
            */
                async rollIntegrationSecret(requestParameters: IntegrationApiRollIntegrationSecretRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Integration> {
                    const response = await this.rollIntegrationSecretRaw(requestParameters, initOverrides);
                    return await response.value();
                }

        }
