import React from "react";
import {
    DataText,
    DateTime,
    Page,
    PageBackButton,
    PageTitle,
    Paper,
    PlaceholderText,
    TextColumn,
    UsagePackageStatusIndicator,
} from "@cobira/ui-library";
import { useApi } from "../../../hooks/useApi";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { Grid, VStack } from "@chakra-ui/react";
import UsagePackageTypeDetails from "../../usagepackagetypes/UsagePackageTypeDetailPage/UsagePackageTypeDetails/UsagePackageTypeDetails";
import { Permission } from "../../../utils/Permissions";
import RestrictedPage from "../../../components/RestrictedPage/RestrictedPage";

const SimCardUsagePackageDetailPage = () => {
    const { usagePackageApi, simCardApi } = useApi();
    const { icc, usagePackageId } = useParams();

    const usagePackageQuery = useQuery(
        ["usagepackages", usagePackageId],
        () => simCardApi.getSimCardUsagePackageById({ icc: icc || "", usagePackageId: usagePackageId || "" }),
        {
            enabled: !!icc && !!usagePackageId,
        },
    );

    const usagePackageTypeQuery = useQuery(
        ["usagepackagetypes", usagePackageQuery?.data?.usagePackageTypeId],
        () =>
            usagePackageApi.getUsagePackageType({
                usagePackageTypeId: usagePackageQuery?.data?.usagePackageTypeId || "",
            }),
        { enabled: !!usagePackageQuery?.data?.usagePackageTypeId },
    );

    return (
        <Page
            title={<PageTitle title={usagePackageTypeQuery?.data?.customName || "SIM Package"} />}
            navigation={<PageBackButton text={"Back to SIM Packages"} path={`/simcards/${icc}?tab=3`} />}
            permissions={{
                values: [Permission.usagePackage.read, Permission.simCard.read],
                deniedComponent: <RestrictedPage />,
            }}
        >
            <VStack gap={4} align={"stretch"} w={"100%"}>
                <Paper header={"SIM Package"} withDivider cardProps={{ w: "100%" }}>
                    <Grid templateColumns="auto auto" gap="4">
                        <TextColumn heading={"Package State"}>
                            <UsagePackageStatusIndicator state={usagePackageQuery?.data?.packageState} withLabel />
                        </TextColumn>
                        <TextColumn heading="Data Remaining" grid isLoading={usagePackageQuery?.isLoading}>
                            <DataText bytes={usagePackageQuery?.data?.dataRemaining?.dataInBytes} decimals={2} />
                        </TextColumn>
                        <TextColumn heading="SMS Remaining" grid isLoading={usagePackageQuery?.isLoading}>
                            <PlaceholderText text={`${usagePackageQuery?.data?.smsRemaining}`} />
                        </TextColumn>
                        <TextColumn heading="Activated At" grid isLoading={usagePackageQuery?.isLoading}>
                            <DateTime value={usagePackageQuery?.data?.activatedAt} />
                        </TextColumn>
                        <TextColumn heading="Expires At" grid isLoading={usagePackageQuery?.isLoading}>
                            <DateTime value={usagePackageQuery?.data?.expiresAt} />
                        </TextColumn>
                        <TextColumn heading="Terminated At" grid isLoading={usagePackageQuery?.isLoading}>
                            <DateTime value={usagePackageQuery?.data?.terminatedAt} />
                        </TextColumn>
                    </Grid>
                </Paper>
                <UsagePackageTypeDetails query={usagePackageTypeQuery} />
            </VStack>
        </Page>
    );
};

export default SimCardUsagePackageDetailPage;
