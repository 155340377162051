/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendSmsActionMsisdnSelectionInner
 */
export interface SendSmsActionMsisdnSelectionInner {
    /**
     * 
     * @type {string}
     * @memberof SendSmsActionMsisdnSelectionInner
     */
    icc: string;
    /**
     * 
     * @type {string}
     * @memberof SendSmsActionMsisdnSelectionInner
     */
    msisdn: string;
}

/**
 * Check if a given object implements the SendSmsActionMsisdnSelectionInner interface.
 */
export function instanceOfSendSmsActionMsisdnSelectionInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "icc" in value;
    isInstance = isInstance && "msisdn" in value;

    return isInstance;
}

export function SendSmsActionMsisdnSelectionInnerFromJSON(json: any): SendSmsActionMsisdnSelectionInner {
    return SendSmsActionMsisdnSelectionInnerFromJSONTyped(json, false);
}

export function SendSmsActionMsisdnSelectionInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendSmsActionMsisdnSelectionInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'icc': json['icc'],
        'msisdn': json['msisdn'],
    };
}

export function SendSmsActionMsisdnSelectionInnerToJSON(value?: SendSmsActionMsisdnSelectionInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'icc': value.icc,
        'msisdn': value.msisdn,
    };
}

