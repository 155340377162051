import React from "react";
import { getHumanizedDurationSinceDate, IndicatorLabel, InfoPopover } from "@cobira/ui-library";
import { DeviceConnectionState } from "../../../api";
import { Box } from "@chakra-ui/react";

export interface DeviceConnectionStatePopoverProps {
    connectionState?: DeviceConnectionState;
    lastConnectedAt?: Date;
}

const DeviceConnectionStatePopover = ({ connectionState, lastConnectedAt }: DeviceConnectionStatePopoverProps) => {
    if (connectionState === "CONNECTED") {
        if (lastConnectedAt) {
            const uptimeDuration = getHumanizedDurationSinceDate(lastConnectedAt, ["d", "h", "m"]);
            return (
                <InfoPopover value={[{ title: "Connection Uptime", description: uptimeDuration }]}>
                    <Box>
                        <IndicatorLabel color={"green"} text={"Connected"} />
                    </Box>
                </InfoPopover>
            );
        } else {
            return <IndicatorLabel color={"green"} text={"Connected"} />;
        }
    }

    if (connectionState === "DISCONNECTED") {
        if (lastConnectedAt) {
            const downtimeDuration = getHumanizedDurationSinceDate(lastConnectedAt, ["d", "h", "m"]);
            return (
                <InfoPopover value={[{ title: "Connection Downtime", description: downtimeDuration }]}>
                    <Box>
                        <IndicatorLabel color={"red"} text={"Not connected"} />
                    </Box>
                </InfoPopover>
            );
        } else {
            return <IndicatorLabel color={"red"} text={"Not connected"} />;
        }
    }

    return <IndicatorLabel color={"gray"} text={"N/A"} />;
};

export default DeviceConnectionStatePopover;
