/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Data
 */
export interface Data {
    /**
     * 
     * @type {number}
     * @memberof Data
     */
    dataInBytes: number;
}

/**
 * Check if a given object implements the Data interface.
 */
export function instanceOfData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dataInBytes" in value;

    return isInstance;
}

export function DataFromJSON(json: any): Data {
    return DataFromJSONTyped(json, false);
}

export function DataFromJSONTyped(json: any, ignoreDiscriminator: boolean): Data {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataInBytes': json['dataInBytes'],
    };
}

export function DataToJSON(value?: Data | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataInBytes': value.dataInBytes,
    };
}

