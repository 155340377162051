/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PhoneNumber
 */
export interface PhoneNumber {
    /**
     * 
     * @type {string}
     * @memberof PhoneNumber
     */
    msisdn: string;
}

/**
 * Check if a given object implements the PhoneNumber interface.
 */
export function instanceOfPhoneNumber(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "msisdn" in value;

    return isInstance;
}

export function PhoneNumberFromJSON(json: any): PhoneNumber {
    return PhoneNumberFromJSONTyped(json, false);
}

export function PhoneNumberFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhoneNumber {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msisdn': json['msisdn'],
    };
}

export function PhoneNumberToJSON(value?: PhoneNumber | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msisdn': value.msisdn,
    };
}

