/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LocationUpdateStatus } from './LocationUpdateStatus';
import {
    LocationUpdateStatusFromJSON,
    LocationUpdateStatusFromJSONTyped,
    LocationUpdateStatusToJSON,
} from './LocationUpdateStatus';

/**
 * 
 * @export
 * @interface LocationUpdate
 */
export interface LocationUpdate {
    /**
     * 
     * @type {string}
     * @memberof LocationUpdate
     */
    imsi: string;
    /**
     * 
     * @type {string}
     * @memberof LocationUpdate
     */
    plmn?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationUpdate
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationUpdate
     */
    networkTechnology: LocationUpdateNetworkTechnologyEnum;
    /**
     * 
     * @type {string}
     * @memberof LocationUpdate
     */
    radioTechnology?: LocationUpdateRadioTechnologyEnum;
    /**
     * 
     * @type {LocationUpdateStatus}
     * @memberof LocationUpdate
     */
    status: LocationUpdateStatus;
    /**
     * 
     * @type {string}
     * @memberof LocationUpdate
     */
    subscriberImsi: string;
    /**
     * 
     * @type {string}
     * @memberof LocationUpdate
     */
    subscriberMsisdn?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationUpdate
     */
    reason?: string;
    /**
     * 
     * @type {Date}
     * @memberof LocationUpdate
     */
    recordedAt: Date;
}


/**
 * @export
 */
export const LocationUpdateNetworkTechnologyEnum = {
    CircuitSwitching: 'CIRCUIT_SWITCHING',
    PacketSwitching: 'PACKET_SWITCHING',
    Unknown: 'UNKNOWN'
} as const;
export type LocationUpdateNetworkTechnologyEnum = typeof LocationUpdateNetworkTechnologyEnum[keyof typeof LocationUpdateNetworkTechnologyEnum];

/**
 * @export
 */
export const LocationUpdateRadioTechnologyEnum = {
    _2G: '2G',
    _3G: '3G',
    _4G: '4G',
    _5G: '5G',
    Unknown: 'UNKNOWN'
} as const;
export type LocationUpdateRadioTechnologyEnum = typeof LocationUpdateRadioTechnologyEnum[keyof typeof LocationUpdateRadioTechnologyEnum];


/**
 * Check if a given object implements the LocationUpdate interface.
 */
export function instanceOfLocationUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "imsi" in value;
    isInstance = isInstance && "networkTechnology" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "subscriberImsi" in value;
    isInstance = isInstance && "recordedAt" in value;

    return isInstance;
}

export function LocationUpdateFromJSON(json: any): LocationUpdate {
    return LocationUpdateFromJSONTyped(json, false);
}

export function LocationUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imsi': json['imsi'],
        'plmn': !exists(json, 'plmn') ? undefined : json['plmn'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'networkTechnology': json['networkTechnology'],
        'radioTechnology': !exists(json, 'radioTechnology') ? undefined : json['radioTechnology'],
        'status': LocationUpdateStatusFromJSON(json['status']),
        'subscriberImsi': json['subscriberImsi'],
        'subscriberMsisdn': !exists(json, 'subscriberMsisdn') ? undefined : json['subscriberMsisdn'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'recordedAt': (new Date(json['recordedAt'])),
    };
}

export function LocationUpdateToJSON(value?: LocationUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imsi': value.imsi,
        'plmn': value.plmn,
        'country': value.country,
        'networkTechnology': value.networkTechnology,
        'radioTechnology': value.radioTechnology,
        'status': LocationUpdateStatusToJSON(value.status),
        'subscriberImsi': value.subscriberImsi,
        'subscriberMsisdn': value.subscriberMsisdn,
        'reason': value.reason,
        'recordedAt': (value.recordedAt.toISOString()),
    };
}

