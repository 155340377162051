import React, { useMemo, useState } from "react";
import { CobiraTabs, Page, PageBackButton, PageTitle } from "@cobira/ui-library";
import SimCardDetailPanel from "./SimCardDetailPanel/SimCardDetailPanel";
import SimCardDataSessionsPanel from "./SimCardDataSessionsPanel/SimCardDataSessionsPanel";
import SimCardLocationUpdatesPanel from "./SimCardLocationUpdatesPanel/SimCardLocationUpdatesPanel";
import SimCardUsagePackagesPanel from "./SimCardUsagePackagesPanel/SimCardUsagePackagesPanel";
import SimCardActivityHistoryPanel from "./SimCardActivityHistoryPanel/SimCardActivityHistoryPanel";
import SimCardNetworkCredentialsPanel from "./SimCardNetworkCredentialsPanel/SimCardNetworkCredentialsPanel";
import { useApi } from "../../../hooks/useApi";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { SimCardActionRequestFilter } from "../../../api";
import { HardwareTabPanel } from "./HardwareTabPanel/HardwareTabPanel";
import { Permission } from "../../../utils/Permissions";
import SimCardDetailActionMenu from "./SimCardDetailActionMenu/SimCardDetailActionMenu";
import RestrictedPage from "../../../components/RestrictedPage/RestrictedPage";
import SimCardActionModals, { SimActionType } from "../../../components/Modal/SimCardActionModals/SimCardActionModals";

const SimCardDetailPage = () => {
    const { simCardApi, deviceApi } = useApi();
    const { icc } = useParams();
    const [chosenAction, setChosenAction] = useState<SimActionType | null>(null);

    const simQuery = useQuery(["simcards", icc], () => simCardApi.getSimCardByIcc({ icc: icc || "" }), {
        enabled: icc != undefined,
    });

    const { data: device } = useQuery(
        ["simcards", icc, "device"],
        () => deviceApi.getDevices({ pageNumber: 0, pageSize: 1, icc: icc }).then(response => response?.content?.at(0)),
        {
            enabled: !!icc,
        },
    );

    const hardwareTabEnabled = useMemo(() => !!device, [device]);

    const filter: SimCardActionRequestFilter = {
        iccLowerBound: icc,
        iccUpperBound: icc,
    };

    return (
        <Page
            title={<PageTitle title={"SIM Card"} />}
            navigation={<PageBackButton text={"Back to inventory"} path={"/simcards"} />}
            pageContext={<SimCardDetailActionMenu onClick={setChosenAction} />}
            permissions={{ values: [Permission.simCard.read], deniedComponent: <RestrictedPage /> }}
        >
            <CobiraTabs
                isLazy
                tabs={[
                    {
                        title: "Details",
                        content: <SimCardDetailPanel query={simQuery} />,
                    },
                    {
                        title: "Data Sessions",
                        content: <SimCardDataSessionsPanel query={simQuery} />,
                    },
                    {
                        title: "Location Updates",
                        content: <SimCardLocationUpdatesPanel query={simQuery} />,
                    },
                    {
                        title: "Usage Packages",
                        content: <SimCardUsagePackagesPanel query={simQuery} />,
                        permissions: {
                            values: [Permission.usagePackage.read],
                        },
                    },
                    {
                        title: "Activity History",
                        content: <SimCardActivityHistoryPanel query={simQuery} />,
                        permissions: {
                            values: [Permission.action.read],
                        },
                    },
                    {
                        title: "Network Credentials",
                        content: <SimCardNetworkCredentialsPanel query={simQuery} />,
                        permissions: {
                            values: [Permission.networkCredential.read, Permission.phoneNumber.read],
                        },
                    },
                    {
                        title: "Device",
                        content: <HardwareTabPanel query={simQuery} />,
                        permissions: {
                            values: [Permission.device.read],
                        },
                        disabled: !hardwareTabEnabled,
                    },
                ]}
            />
            <SimCardActionModals actionType={chosenAction} filter={filter} onClose={() => setChosenAction(null)} />
        </Page>
    );
};

export default SimCardDetailPage;
