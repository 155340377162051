/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MessageType = {
    Gsm7Bit: 'GSM_7_BIT',
    Ucs2: 'UCS2'
} as const;
export type MessageType = typeof MessageType[keyof typeof MessageType];


export function MessageTypeFromJSON(json: any): MessageType {
    return MessageTypeFromJSONTyped(json, false);
}

export function MessageTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageType {
    return json as MessageType;
}

export function MessageTypeToJSON(value?: MessageType | null): any {
    return value as any;
}

