import { SimCard } from "../../../../api";
import { Grid, Heading, HStack } from "@chakra-ui/react";
import { BillingGroupIcon, IconLink, NotFound, Paper, PlaceholderText, TextColumn } from "@cobira/ui-library";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../../../hooks/useApi";

export interface SimCardBillingGroupPaperProps {
    simCard?: SimCard;
    isLoading?: boolean;
}

const SimCardBillingGroupPaper = ({ simCard, isLoading }: SimCardBillingGroupPaperProps) => {
    const { billingGroupApi } = useApi();

    const { data: billingGroup, isLoading: loadingBillingGroup } = useQuery(
        ["billinggroups", simCard?.billingGroupId],
        () => billingGroupApi.getBillingGroup({ billingGroupId: simCard?.billingGroupId || "" }),
        { enabled: !!simCard?.billingGroupId },
    );

    if (!isLoading && !simCard?.billingGroupId) {
        return (
            <Paper header={"Billing Group"} withDivider>
                <NotFound icon={BillingGroupIcon} resource={"Billing Group"} withIcon />
            </Paper>
        );
    }

    return (
        <Paper header={"Billing Group"} withDivider>
            <Grid templateColumns="auto auto" gap="4" rowGap={2}>
                <TextColumn
                    heading={
                        <HStack w={"100%"} gap={2}>
                            <Heading size={"sm"}>Billing Group</Heading>
                            <IconLink path={`/billing-groups/${billingGroup?.id}`} mt={"-0.35em"} />
                        </HStack>
                    }
                    grid
                    isLoading={loadingBillingGroup}
                >
                    <PlaceholderText text={billingGroup?.name} />
                </TextColumn>
                <TextColumn heading={"Billing Group Description"} grid isLoading={loadingBillingGroup}>
                    <PlaceholderText text={billingGroup?.description} />
                </TextColumn>
            </Grid>
        </Paper>
    );
};

export default SimCardBillingGroupPaper;
