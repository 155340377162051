import {
    BillingGroupIcon,
    chakraIcon,
    DashboardIcon,
    IntegrationIcon,
    SidebarItem,
    SimCardIcon,
    UsagePackageTypeIcon,
    UserIcon,
} from "@cobira/ui-library";
import { Permission } from "../../utils/Permissions";
import { faRouter } from "@fortawesome/pro-regular-svg-icons";

const SidebarItems: SidebarItem[] = [
    {
        title: "Dashboard",
        path: "/dashboards/account",
        icon: DashboardIcon,
    },
    {
        title: "SIM Management",
        icon: SimCardIcon,
        permissions: {
            values: [Permission.simCard.read],
        },
        items: [
            { title: "Inventory", path: "/simcards" },
            { title: "Activity", path: "/actions", permissions: { values: [Permission.action.read] } },
        ],
    },
    {
        title: "Device Management",
        icon: chakraIcon(faRouter),
        permissions: {
            values: [Permission.device.read],
        },
        items: [
            { title: "Inventory", path: "/devices" },
            { title: "Activity", path: "/deviceactions", permissions: { values: [Permission.action.read] } },
        ],
    },
    {
        title: "Billing Groups",
        path: "/billing-groups",
        icon: BillingGroupIcon,
        permissions: {
            values: [Permission.billingGroup.read],
        },
    },
    {
        title: "Integrations",
        path: "/integration",
        icon: IntegrationIcon,
        permissions: {
            values: [Permission.integration.read],
        },
    },
    {
        title: "Usage Package Types",
        path: "/usage-package-types",
        icon: UsagePackageTypeIcon,
        permissions: {
            values: [Permission.usagePackage.read],
        },
    },
    {
        title: "Users",
        path: "/users",
        icon: UserIcon,
        permissions: {
            values: [Permission.user.read],
        },
    },
];

export default SidebarItems;
