/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImeiLockUpdateBehaviour } from './ImeiLockUpdateBehaviour';
import {
    ImeiLockUpdateBehaviourFromJSON,
    ImeiLockUpdateBehaviourFromJSONTyped,
    ImeiLockUpdateBehaviourToJSON,
} from './ImeiLockUpdateBehaviour';
import type { ImeiValueType } from './ImeiValueType';
import {
    ImeiValueTypeFromJSON,
    ImeiValueTypeFromJSONTyped,
    ImeiValueTypeToJSON,
} from './ImeiValueType';

/**
 * 
 * @export
 * @interface UpdateImeiLockEntry
 */
export interface UpdateImeiLockEntry {
    /**
     * 
     * @type {string}
     * @memberof UpdateImeiLockEntry
     */
    icc: string;
    /**
     * 
     * @type {ImeiValueType}
     * @memberof UpdateImeiLockEntry
     */
    imeiValueType: ImeiValueType;
    /**
     * If the `FULL` value type is selected, the IMEI that will be persisted and used for checking for IMEI violations will be the 14 digit variant.
     * This means it is without both the Software Version (SV) and the Check Digit.
     * For an explanation of the IMEI variants please refer to the following website: https://en.wikipedia.org/wiki/International_Mobile_Equipment_Identity
     * 
     * Otherwise the specified value will be used as the TAC and be checked against observed IMEIs for a match on the TAC part.
     * @type {string}
     * @memberof UpdateImeiLockEntry
     */
    imeiValue: string;
    /**
     * 
     * @type {ImeiLockUpdateBehaviour}
     * @memberof UpdateImeiLockEntry
     */
    updateBehaviour: ImeiLockUpdateBehaviour;
}

/**
 * Check if a given object implements the UpdateImeiLockEntry interface.
 */
export function instanceOfUpdateImeiLockEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "icc" in value;
    isInstance = isInstance && "imeiValueType" in value;
    isInstance = isInstance && "imeiValue" in value;
    isInstance = isInstance && "updateBehaviour" in value;

    return isInstance;
}

export function UpdateImeiLockEntryFromJSON(json: any): UpdateImeiLockEntry {
    return UpdateImeiLockEntryFromJSONTyped(json, false);
}

export function UpdateImeiLockEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateImeiLockEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'icc': json['icc'],
        'imeiValueType': ImeiValueTypeFromJSON(json['imeiValueType']),
        'imeiValue': json['imeiValue'],
        'updateBehaviour': ImeiLockUpdateBehaviourFromJSON(json['updateBehaviour']),
    };
}

export function UpdateImeiLockEntryToJSON(value?: UpdateImeiLockEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'icc': value.icc,
        'imeiValueType': ImeiValueTypeToJSON(value.imeiValueType),
        'imeiValue': value.imeiValue,
        'updateBehaviour': ImeiLockUpdateBehaviourToJSON(value.updateBehaviour),
    };
}

