import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { CobiraTable, CountryInfo, DataText, DateTime, getCountry, SlimUuid, usePagination } from "@cobira/ui-library";
import NetworkInfo from "../NetworkInfo/NetworkInfo";
import { UsagePackageCell } from "../Cells/UsagePackageCell/UsagePackageCell";
import { useApi } from "../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { DataSession, SimCard } from "../../api";

const dataSessionColumn = createColumnHelper<DataSession>();
const COLUMNS = [
    dataSessionColumn.accessor("identifier", {
        id: "id",
        header: "Usage ID",
        cell: row => <SlimUuid uuid={row.getValue() || ""} />,
    }),
    dataSessionColumn.accessor("imsi", {
        id: "imsi",
        header: "IMSI",
        cell: row => row.getValue(),
    }),
    dataSessionColumn.accessor("plmn", {
        id: "plmn",
        header: "Network",
        cell: row => <NetworkInfo plmn={row.getValue()} />,
    }),
    dataSessionColumn.accessor("country", {
        id: "country",
        header: "Country",
        cell: row => <CountryInfo country={getCountry(row.getValue())} />,
    }),
    dataSessionColumn.accessor("openedAt", {
        id: "openedAt",
        header: "Opened At",
        cell: row => <DateTime value={row.getValue()} />,
    }),
    dataSessionColumn.accessor("closedAt", {
        id: "closedAt",
        header: "Closed At",
        cell: row => <DateTime value={row.getValue()} />,
    }),
    dataSessionColumn.accessor("totalBytes", {
        id: "totalBytes",
        header: "Total Usage",
        cell: row => <DataText bytes={row.getValue()} decimals={2} />,
    }),
    dataSessionColumn.accessor(row => row, {
        id: "bundle",
        header: "Bundle",
        cell: row => <UsagePackageCell icc={row.getValue().icc} usagePackageId={row.getValue().usagePackageId} />,
    }),
];

export interface DataUsageTableProps {
    simCard?: SimCard;
    tableKey: string;
}

const DataUsageTable = ({ simCard, tableKey }: DataUsageTableProps) => {
    const { simCardApi } = useApi();
    const { pageState, setPageState } = usePagination({
        pageIndex: 0,
        pageSize: 20,
    });

    const { data: dataSessions, isLoading: isLoadingDataSessions } = useQuery(
        ["simcards", simCard?.icc, "usage-records", "data", pageState],
        () => {
            const now = new Date();
            const oneMonthAgo = new Date(now);
            oneMonthAgo.setMonth(now.getMonth() - 1);
            return simCardApi.getSimCardDataSessions({
                icc: simCard?.icc || "",
                pageNumber: pageState.pageIndex,
                pageSize: pageState.pageSize,
                fromDate: oneMonthAgo,
                toDate: now,
            });
        },
        { enabled: simCard?.icc !== undefined },
    );
    return (
        <CobiraTable
            columns={COLUMNS}
            data={dataSessions?.content || []}
            isLoading={isLoadingDataSessions}
            withPagination={{
                pageSize: pageState.pageSize,
                pageIndex: pageState.pageIndex,
                onPaginationChange: setPageState,
                totalRowCount: dataSessions?.pageProperties?.totalElements || 0,
                totalPageCount: dataSessions?.pageProperties?.totalPages || 0,
            }}
            withColumnSelection={{
                tableKey: tableKey,
                maxColumnCount: 7,
                minColumnCount: 2,
            }}
        />
    );
};

export default DataUsageTable;
