import {
    DeviceActionRequestFilter,
    DeviceApiGetDevicesRequest,
    SimCardActionRequestFilter,
    SimCardApiGetSimCardsRequest,
} from "../api";

export const mapFromActionFilterToSearchFilter = (
    pageNumber: number,
    pageSize: number,
    filter?: SimCardActionRequestFilter,
): SimCardApiGetSimCardsRequest => ({
    pageNumber: pageNumber,
    pageSize: pageSize,
    simConfigurationId: filter?.simConfigurationId || undefined,
    labels: filter?.labels || undefined,
    networkState: filter?.networkState || undefined,
    iccUpperBound: filter?.iccUpperBound || undefined,
    iccLowerBound: filter?.iccLowerBound || undefined,
    billingGroupId: filter?.billingGroupId || undefined,
    msisdnSearch: filter?.msisdnSearch || undefined,
    search: filter?.search || undefined,
    networkCredentialTypeId: filter?.networkCredentialTypeId || undefined,
    country: filter?.country || undefined,
    plmn: filter?.plmnSearch || undefined,
    usagePackageTypeSearch: filter?.usagePackageTypeId || undefined,
});

export const mapFromSearchFilterToActionFilter = (
    filter?: SimCardApiGetSimCardsRequest,
): SimCardActionRequestFilter => ({
    simConfigurationId: filter?.simConfigurationId || undefined,
    labels: filter?.labels || undefined,
    networkState: filter?.networkState || undefined,
    iccUpperBound: filter?.iccUpperBound || undefined,
    iccLowerBound: filter?.iccLowerBound || undefined,
    billingGroupId: filter?.billingGroupId || undefined,
    msisdnSearch: filter?.msisdnSearch || undefined,
    search: filter?.search || undefined,
    networkCredentialTypeId: filter?.networkCredentialTypeId || undefined,
    country: filter?.country || undefined,
    plmnSearch: filter?.plmn || undefined,
    usagePackageTypeId: filter?.usagePackageTypeSearch || undefined,
});

export const mapFromDeviceActionFilterToSearchFilter = (
    pageNumber: number,
    pageSize: number,
    filter?: DeviceActionRequestFilter,
): DeviceApiGetDevicesRequest => ({
    pageNumber: pageNumber,
    pageSize: pageSize,
    deviceConnectionState: filter?.deviceConnectionState || undefined,
    icc: filter?.icc || undefined,
    identifier: filter?.identifier || undefined,
    search: filter?.search || undefined,
    model: filter?.model || undefined,
});

export const mapBooleanFilter = (value: boolean | null | undefined) => {
    if (value === null || value === undefined) {
        return undefined;
    } else {
        return value;
    }
};
