import {
    Action,
    ActionCompletedNotification,
    ActionFailedNotification,
    ConfirmModal,
    pluralize,
    useActionNotification,
} from "@cobira/ui-library";
import { useMutation } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { Text, VStack } from "@chakra-ui/react";
import { DeviceActionRequestFilter, DeviceApiRebootDevicesRequest } from "../../../../api";
import { useApi } from "../../../../hooks/useApi";
import { useDeviceCount } from "../../../../hooks/useDeviceCount";
import ActionInProgressNotification from "../../../ActionNotification/ActionInProgressNotification/ActionInProgressNotification";

export interface RebootDeviceActionModalProps {
    filter: DeviceActionRequestFilter;
    isOpen: boolean;
    onClose: () => void;
}

const RebootDeviceActionModal = ({ isOpen, filter, onClose }: RebootDeviceActionModalProps) => {
    const { deviceApi, actionApi } = useApi();
    const { trackAction } = useActionNotification({});

    const deviceCount = useDeviceCount({ filter });

    const rebootDevice = useMutation<Action[], Error, DeviceApiRebootDevicesRequest>(
        variables => deviceApi.rebootDevices(variables),
        {
            onSuccess: data => {
                data.forEach(action => {
                    trackAction({
                        action: action,
                        fetchAction: actionId => actionApi.getAction({ actionId: actionId }),
                        options: {
                            successMessage: successMessage,
                            errorMessage: errorMessage,
                            loadingMessage: loadingMessage(action),
                        },
                    });
                });
            },
        },
    );

    const handleConfirm = useCallback(
        (confirmed: boolean) => {
            if (confirmed) {
                rebootDevice.mutate({
                    deviceRebootRequest: {
                        deviceFilter: filter,
                    },
                });
            }
            onClose();
        },
        [filter, onClose, rebootDevice],
    );

    const successMessage = useCallback(
        (data: Action) => {
            return (
                <ActionCompletedNotification
                    action={data}
                    successTitle={"Reboot Action Completed"}
                    successMessage={`Reboot of ${data.totalItems} ${pluralize("device", deviceCount)} completed`}
                    failureComponent={<ActionFailedNotification title={"Failed to reboot"} />}
                />
            );
        },
        [deviceCount],
    );
    const errorMessage = useCallback(() => <ActionFailedNotification title={"Failed to reboot"} />, []);
    const loadingMessage = useCallback(
        (action: Action) => (
            <ActionInProgressNotification
                actionApi={actionApi}
                actionId={action.id}
                title={"Rebooting devices"}
                message={(processedItemCount, totalItemCount) =>
                    `Reboot scheduled for ${processedItemCount} / ${totalItemCount} ${pluralize("device", deviceCount)}`
                }
            />
        ),
        [actionApi, deviceCount],
    );

    return (
        <ConfirmModal
            title={`Reboot Device`}
            onClose={handleConfirm}
            isOpen={isOpen}
            description={
                <VStack w={"100%"} align={"stretch"}>
                    <Text>
                        Schedule Device Reboot for <b>{deviceCount}</b> {pluralize("Device", deviceCount)}
                    </Text>
                </VStack>
            }
        />
    );
};

export default RebootDeviceActionModal;
