import { Country, rangeInput, singleInput, usePersistedSearch } from "@cobira/ui-library";
import { BillingGroup, NetworkCredentialType, NetworkState, SimConfiguration, UsagePackageType } from "../api";
import { NetworkStateLabels } from "../labels/NetworkStateLabels";

export interface UseSimCardSearchProps {
    onChange?: () => void;
}

const useSimCardSearch = ({ onChange }: UseSimCardSearchProps) => {
    return usePersistedSearch({
        persistenceKey: "sim-card-table-search",
        config: {
            search: singleInput<string>("Search"),
            msisdnSearch: singleInput<string>("MSISDN Search"),
            iccRange: rangeInput<string>("ICC Range"),
            billingGroup: singleInput<BillingGroup>("Billing Group", { display: value => value.name }),
            simCardState: singleInput<NetworkState>("SIM Card State", { display: value => NetworkStateLabels[value] }),
            country: singleInput<Country>("Country", { display: value => `${value.name} (${value.iso2})` }),
            networkCredentialType: singleInput<NetworkCredentialType>("Network Credential Type", {
                display: value => `${value.imsiProvider} (${value.plmn})`,
            }),
            simConfiguration: singleInput<SimConfiguration>("SIM Configuration", {
                display: value => value.name,
            }),
            plmnSearch: singleInput<string>("PLMN Search"),
            usagePackageType: singleInput<UsagePackageType>("Bundle", { display: value => value.customName }),
        },
        onChange: onChange,
    });
};

export default useSimCardSearch;
