/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeviceACLUpdateRequestAclConfigsInner } from './DeviceACLUpdateRequestAclConfigsInner';
import {
    DeviceACLUpdateRequestAclConfigsInnerFromJSON,
    DeviceACLUpdateRequestAclConfigsInnerFromJSONTyped,
    DeviceACLUpdateRequestAclConfigsInnerToJSON,
} from './DeviceACLUpdateRequestAclConfigsInner';

/**
 * ACL update request containing one or more ACL configurations to apply to the specified devices. 
 * If a device is specified multiple times, the last configuration will be the effective one.
 * @export
 * @interface DeviceACLUpdateRequest
 */
export interface DeviceACLUpdateRequest {
    /**
     * 
     * @type {Array<DeviceACLUpdateRequestAclConfigsInner>}
     * @memberof DeviceACLUpdateRequest
     */
    aclConfigs?: Array<DeviceACLUpdateRequestAclConfigsInner>;
}

/**
 * Check if a given object implements the DeviceACLUpdateRequest interface.
 */
export function instanceOfDeviceACLUpdateRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeviceACLUpdateRequestFromJSON(json: any): DeviceACLUpdateRequest {
    return DeviceACLUpdateRequestFromJSONTyped(json, false);
}

export function DeviceACLUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceACLUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aclConfigs': !exists(json, 'aclConfigs') ? undefined : ((json['aclConfigs'] as Array<any>).map(DeviceACLUpdateRequestAclConfigsInnerFromJSON)),
    };
}

export function DeviceACLUpdateRequestToJSON(value?: DeviceACLUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aclConfigs': value.aclConfigs === undefined ? undefined : ((value.aclConfigs as Array<any>).map(DeviceACLUpdateRequestAclConfigsInnerToJSON)),
    };
}

