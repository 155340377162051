import { ActionProgressNotification } from "@cobira/ui-library";
import React, { ReactNode } from "react";
import { ActionApi } from "../../../api";

export interface ActionInProgressNotificationProps {
    actionApi: ActionApi;
    actionId: string;
    title: ReactNode;
    message: (processedItemCount: number, totalItemCount: number) => ReactNode;
}

const ActionInProgressNotification = ({ actionApi, actionId, title, message }: ActionInProgressNotificationProps) => {
    return (
        <ActionProgressNotification
            actionId={actionId}
            query={{ queryFn: () => actionApi.getAction({ actionId: actionId }) }}
            title={title}
            message={action => message(action.itemsProcessed || 0, action.totalItems || 0)}
        />
    );
};

export default ActionInProgressNotification;
