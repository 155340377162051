import React, { useMemo } from "react";
import { Device } from "../../../api";
import {
    chakraIcon,
    formatDateTime,
    getHumanizedDurationSinceDate,
    getHumanizedDurationSinceDateString,
    InfoCard,
    InfoCards,
    LastUpdatedIcon,
    OnOffInfoCard,
} from "@cobira/ui-library";
import { faTachographDigital } from "@fortawesome/pro-solid-svg-icons";

export interface DeviceInfoCardProps {
    device: Device;
}

const DeviceInfoCards = ({ device }: DeviceInfoCardProps) => {
    const lastConnectedDuration = device.lastConnectedAt
        ? getHumanizedDurationSinceDate(device.lastConnectedAt, ["d", "h", "m"])
        : "N/A";
    const lastUpdated = getHumanizedDurationSinceDateString(device.updatedAt, {
        units: ["h", "m"],
        justNowSeconds: 30,
        justNowTemplate: "A few moments ago",
        timeAgoTemplate: "{duration} ago",
    });

    const ConnectionDurationInfoCard = useMemo(() => {
        if (device.connectionState === "CONNECTED") {
            return (
                <InfoCard
                    title={"Connection Uptime"}
                    tooltip={`Connected since ${formatDateTime(device.lastConnectedAt)}`}
                    value={lastConnectedDuration}
                    icon={LastUpdatedIcon}
                />
            );
        }
        if (device.connectionState === "DISCONNECTED") {
            return (
                <InfoCard
                    title={"Connection Downtime"}
                    tooltip={`Disconnected since ${formatDateTime(device.lastConnectedAt)}`}
                    value={lastConnectedDuration}
                    icon={LastUpdatedIcon}
                />
            );
        } else {
            return <InfoCard title={"Connection Uptime"} value={"N/A"} icon={LastUpdatedIcon} />;
        }
    }, [device, lastConnectedDuration]);
    return (
        <InfoCards>
            <OnOffInfoCard
                title={"Status"}
                tooltip={"Status"}
                onStateText={"Online"}
                offStateText={"Offline"}
                state={device.connectionState === "CONNECTED"}
            />
            {ConnectionDurationInfoCard}
            <InfoCard
                title={"Device Model"}
                tooltip={"Device Model"}
                value={device.model ?? "N/A"}
                icon={chakraIcon(faTachographDigital)}
            />
            <InfoCard
                title={"Last Updated"}
                tooltip={`Last updated at ${formatDateTime(device.updatedAt)}`}
                value={lastUpdated}
                icon={LastUpdatedIcon}
            />
        </InfoCards>
    );
};

export default DeviceInfoCards;
