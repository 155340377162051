import { WebhookSubscriptionType } from "../api";

export const WebhookSubscriptionTypeLabels = {
    [WebhookSubscriptionType.MobileOriginatedSms]: "Mobile Originated SMS",
    [WebhookSubscriptionType.Unknown]: "Unknown",
    [WebhookSubscriptionType.SimCardIpAddressUpdated]: "SIM Card IP address updated",
    [WebhookSubscriptionType.SimCardActiveImsiUpdated]: "SIM Card active IMSI updated",
    [WebhookSubscriptionType.SimCardImeiUpdated]: "SIM Card IMEI updated",
    [WebhookSubscriptionType.ImeiLockViolated]: "IMEI lock violated",
    [WebhookSubscriptionType.UsagePackageUpdated]: "Usage Package updated",
    [WebhookSubscriptionType.ActionUpdated]: "Action updated",
    [WebhookSubscriptionType.ActionItemUpdated]: "Action Item updated",
};
