/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataPoint } from './DataPoint';
import {
    DataPointFromJSON,
    DataPointFromJSONTyped,
    DataPointToJSON,
} from './DataPoint';
import type { DimensionEntry } from './DimensionEntry';
import {
    DimensionEntryFromJSON,
    DimensionEntryFromJSONTyped,
    DimensionEntryToJSON,
} from './DimensionEntry';
import type { MetricGroup } from './MetricGroup';
import {
    MetricGroupFromJSON,
    MetricGroupFromJSONTyped,
    MetricGroupToJSON,
} from './MetricGroup';
import type { TimeBucket } from './TimeBucket';
import {
    TimeBucketFromJSON,
    TimeBucketFromJSONTyped,
    TimeBucketToJSON,
} from './TimeBucket';

/**
 * 
 * @export
 * @interface MetricResponse
 */
export interface MetricResponse {
    /**
     * 
     * @type {DataPoint}
     * @memberof MetricResponse
     */
    dataPoint: DataPoint;
    /**
     * 
     * @type {MetricGroup}
     * @memberof MetricResponse
     */
    group: MetricGroup;
    /**
     * 
     * @type {TimeBucket}
     * @memberof MetricResponse
     */
    bucket: TimeBucket;
    /**
     * 
     * @type {string}
     * @memberof MetricResponse
     */
    groupId: string;
    /**
     * 
     * @type {Date}
     * @memberof MetricResponse
     */
    forDateTime: Date;
    /**
     * The metric value.
     * @type {number}
     * @memberof MetricResponse
     */
    value: number;
    /**
     * 
     * @type {Array<DimensionEntry>}
     * @memberof MetricResponse
     */
    dimensions?: Array<DimensionEntry> | null;
}

/**
 * Check if a given object implements the MetricResponse interface.
 */
export function instanceOfMetricResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dataPoint" in value;
    isInstance = isInstance && "group" in value;
    isInstance = isInstance && "bucket" in value;
    isInstance = isInstance && "groupId" in value;
    isInstance = isInstance && "forDateTime" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function MetricResponseFromJSON(json: any): MetricResponse {
    return MetricResponseFromJSONTyped(json, false);
}

export function MetricResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetricResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataPoint': DataPointFromJSON(json['dataPoint']),
        'group': MetricGroupFromJSON(json['group']),
        'bucket': TimeBucketFromJSON(json['bucket']),
        'groupId': json['groupId'],
        'forDateTime': (new Date(json['forDateTime'])),
        'value': json['value'],
        'dimensions': !exists(json, 'dimensions') ? undefined : (json['dimensions'] === null ? null : (json['dimensions'] as Array<any>).map(DimensionEntryFromJSON)),
    };
}

export function MetricResponseToJSON(value?: MetricResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataPoint': DataPointToJSON(value.dataPoint),
        'group': MetricGroupToJSON(value.group),
        'bucket': TimeBucketToJSON(value.bucket),
        'groupId': value.groupId,
        'forDateTime': (value.forDateTime.toISOString()),
        'value': value.value,
        'dimensions': value.dimensions === undefined ? undefined : (value.dimensions === null ? null : (value.dimensions as Array<any>).map(DimensionEntryToJSON)),
    };
}

