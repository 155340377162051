/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DeviceConnectionState = {
    Connected: 'CONNECTED',
    Disconnected: 'DISCONNECTED',
    Unknown: 'UNKNOWN'
} as const;
export type DeviceConnectionState = typeof DeviceConnectionState[keyof typeof DeviceConnectionState];


export function DeviceConnectionStateFromJSON(json: any): DeviceConnectionState {
    return DeviceConnectionStateFromJSONTyped(json, false);
}

export function DeviceConnectionStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceConnectionState {
    return json as DeviceConnectionState;
}

export function DeviceConnectionStateToJSON(value?: DeviceConnectionState | null): any {
    return value as any;
}

