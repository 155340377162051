import React from "react";
import { ActionActorCell, BasicModal, PlaceholderText, TextColumn } from "@cobira/ui-library";
import { HStack, VStack } from "@chakra-ui/react";
import useActionActor from "../../../hooks/useActionActor";
import { LanHostAndACL } from "../../../hooks/useLanHostACLAggregations";

export interface DeviceACLStateModalProps {
    isOpen: boolean;
    onClose: () => void;
    host: LanHostAndACL;
}

const DeviceACLStateModal = ({ isOpen, onClose, host }: DeviceACLStateModalProps) => {
    const actionActorResolver = useActionActor();
    return (
        <BasicModal isOpen={isOpen} onClose={onClose} title={"ACL Details"}>
            <VStack w={"100%"} align={"stretch"}>
                <HStack w={"100%"} align={"stretch"} gap={4}>
                    <TextColumn heading={"ACL Rule"}>{host.configured ? "Allowed" : "Blocked"}</TextColumn>
                    <TextColumn heading={"Updated by"}>
                        {host.updatedBy && (
                            <ActionActorCell actionId={""} actor={host.updatedBy} resolvers={actionActorResolver} />
                        )}
                        {!host.updatedBy && <PlaceholderText text={null} />}
                    </TextColumn>
                </HStack>
                <TextColumn heading={"Notes"}>
                    <PlaceholderText text={host.note} />
                </TextColumn>
            </VStack>
        </BasicModal>
    );
};

export default DeviceACLStateModal;
