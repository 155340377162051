/* tslint:disable */
/* eslint-disable */
/**
 * Cobira CMP API
 * ### Documentation for interaction with the Cobira Connectivity Management Platform APIs. ---  *Cobira ApS, Proprietary and Confidential*  *All Right reserved © 2025*
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@cobira.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const NetworkState = {
    Active: 'ACTIVE',
    Suspended: 'SUSPENDED',
    Terminated: 'TERMINATED',
    Idle: 'IDLE'
} as const;
export type NetworkState = typeof NetworkState[keyof typeof NetworkState];


export function NetworkStateFromJSON(json: any): NetworkState {
    return NetworkStateFromJSONTyped(json, false);
}

export function NetworkStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): NetworkState {
    return json as NetworkState;
}

export function NetworkStateToJSON(value?: NetworkState | null): any {
    return value as any;
}

