import React, { ReactNode } from "react";
import { SimCardNetworkState } from "../../../api";
import NetworkInfo from "../../NetworkInfo/NetworkInfo";

interface NetworkStateNetworkInfoProps {
    networkState?: SimCardNetworkState;
    placeholderComponent?: ReactNode;
}

export const NetworkStateNetwork = ({ networkState, placeholderComponent }: NetworkStateNetworkInfoProps) => {
    if (!networkState?.network) {
        return <>{placeholderComponent}</>;
    }

    return <NetworkInfo plmn={networkState?.network?.plmn} />;
};
